import React from "react";

import { Typography, Layout, Card, Switch, Row, Col, Space, Select, Button, Divider } from "antd";
import { shallow } from "zustand/shallow";

import { WorkflowSettingsWrapper } from "./WorkflowSettings.styled";
import useStore, { RFState } from "../../stores";
import { IWorkflowResponse } from "types";
import type { v4 } from "uuid";
import { useParams } from "react-router-dom";

interface Iprops {
    listWorkflows?: IWorkflowResponse[];
    listWorkflowsByIds?: IWorkflowResponse[];
    setPage?: React.Dispatch<React.SetStateAction<number>>;
    isLoadMore?: boolean;
}

const { Title } = Typography;
const { Content } = Layout;

const selector = (state: RFState) => ({
    settings: state.settings,
    updateWorkflowSettings: state.updateWorkflowSettings,
});

const WorkflowSettings = ({ listWorkflows, listWorkflowsByIds, setPage, isLoadMore }: Iprops) => {
    const { settings, updateWorkflowSettings } = useStore(selector, shallow);
    const { id } = useParams();
    const [listDataOption, setListDataOption] = React.useState<
        { value: string | number | typeof v4 | undefined; label: string }[]
    >([]);

    React.useEffect(() => {
        if (listWorkflows && listWorkflows.length > 0) {
            const uniqueWorkflows = listWorkflows
                .concat(listWorkflowsByIds ?? [])
                .reduce<IWorkflowResponse[]>((acc, workflow) => {
                    if (!acc.some((existing) => existing.id === workflow.id)) {
                        acc.push(workflow);
                    }
                    return acc;
                }, [])
                .filter((wf) => wf.id != id)
                .map((workflow) => ({ value: workflow.id, label: workflow.name }));

            const filteredExistingData = listDataOption.filter(
                (existing) => !uniqueWorkflows.some((wf) => wf.value === existing.value)
            );

            setListDataOption([...uniqueWorkflows, ...filteredExistingData]);
        }
    }, [listWorkflows, listWorkflowsByIds]);

    const handleChangeSettings = (checked: boolean) => {
        updateWorkflowSettings({
            ...settings,
            communicateOnlyDuringBusinessHours: checked,
        });
    };

    const handleLoadMore = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
        e.stopPropagation();
        if (setPage && isLoadMore) setPage((prev) => prev + 1);
    };

    return (
        <WorkflowSettingsWrapper>
            <Content>
                <Title level={2}>Workflow Settings</Title>
                <Card title="General" bordered={false}>
                    <Row style={{ marginBottom: "20px" }}>
                        <Col>
                            <Switch
                                checked={settings?.communicateOnlyDuringBusinessHours ?? false}
                                onChange={(checked) => handleChangeSettings(checked)}
                            />
                        </Col>
                        <Col style={{ marginLeft: "16px" }}>
                            <Typography>Contact only during business hours</Typography>
                        </Col>
                    </Row>
                    <Space className="excluded-workflow-ids">
                        <Typography>{`Don't run this workflow if contact is enrolled in other workflows:`}</Typography>
                        <Select
                            placeholder="Select..."
                            style={{ width: "300px" }}
                            defaultValue={settings.excludedWorkflowIds}
                            mode="multiple"
                            onDropdownVisibleChange={(isOpen) => !isOpen && setPage && setPage(1)}
                            options={listDataOption}
                            onChange={(e: number[]) =>
                                updateWorkflowSettings({
                                    ...settings,
                                    excludedWorkflowIds: e,
                                })
                            }
                            dropdownRender={(menu) => (
                                <div>
                                    {menu}
                                    {isLoadMore && (
                                        <div>
                                            <Divider style={{ margin: "8px 0" }} />
                                            <Button onClick={(event) => handleLoadMore(event)}>Load more</Button>
                                        </div>
                                    )}
                                </div>
                            )}
                        />
                    </Space>
                </Card>
            </Content>
        </WorkflowSettingsWrapper>
    );
};

export default WorkflowSettings;
