import React from "react";

import { Typography } from "antd";
import { SaveOutlined } from "@ant-design/icons";
import { EditableTextWrapper } from "./EditableTextWrapper.styled";
// import useClickAway from "hooks/useClickAway";

interface IEditableTextProps {
    text: string;
    onSave?: (value: string) => void;
}

export default function EditableText({ text, onSave }: IEditableTextProps) {
    const [currentText, setCurrentText] = React.useState<string>("");
    const [isEditing, setIsEditing] = React.useState<boolean>(false);
    const paragraphRef = React.useRef(null);

    const handleChange = (value: string) => {
        setCurrentText(value);
        if (onSave) {
            setIsEditing(false);
            onSave(value);
        }
    };

    const handleStart = () => {
        setIsEditing(true);
        setCurrentText(text);
    };

    const handleCancel = () => {
        setIsEditing(false);
    };

    const handleClickSaveButton = () => {
        setIsEditing(false);
        if (onSave) onSave(currentText);
    };

    // useClickAway(paragraphRef, () => {
    //     setIsEditing(false);
    // });

    return (
        <EditableTextWrapper ref={paragraphRef}>
            <Typography.Paragraph
                editable={{
                    onChange: handleChange,
                    onStart: handleStart,
                    onCancel: handleCancel,
                    enterIcon: null,
                    tooltip: false,
                    editing: isEditing,
                    triggerType: ["icon", "text"],
                }}
            >
                {text}
            </Typography.Paragraph>
            {isEditing && (
                <span>
                    <SaveOutlined style={{ fontSize: "16px" }} onClick={() => handleClickSaveButton()} />
                </span>
            )}
        </EditableTextWrapper>
    );
}
