import { useQuery } from "@tanstack/react-query";
import { StaffAPI } from "apis/staff";

import { IStaff, TCommonPagination } from "types";

export function useGetStaffs() {
    return useQuery<TCommonPagination<IStaff>, Error>(["getStaffs"], () => StaffAPI.getStaffs(), {
        refetchOnWindowFocus: false,
    });
}
