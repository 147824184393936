import { object, string } from "yup";

export const sendEmailSchema = object({
    to: string().required(),
    message: string().required(),
});

export const sendSMSSchema = object({
    to: string().required(),
    message: string().required(),
});
