/* eslint-disable @typescript-eslint/no-misused-promises */
import React from "react";

import { Checkbox, Col, Form, Input, Row, Select } from "antd";
import { isEmpty } from "lodash-es";

import { IFormsProps } from "../../../types";
import FormFooter from "../FormFooter";
import TextArea from "antd/es/input/TextArea";
import { CheckboxChangeEvent } from "antd/es/checkbox";

interface IFormInputs {
    staff_ids: number[];
    ticket_type: string;
    subject: string;
    description: string;
}

interface IListStaffs {
    label: string;
    value: number;
}

export const TICKET_TYPES = [
    { value: "consult_request", label: "Consult Request" },
    { value: "question", label: "Question" },
    { value: "email", label: "Email" },
    { value: "sms", label: "Sms" },
    { value: "meta_facebook_messenger", label: "Mete Facebook Messenger" },
    { value: "meta_instagram_messenger", label: "Meta Instagram Messenger" },
    { value: "task", label: "Task" },
    { value: "reminder", label: "Reminder" },
    { value: "file_upload", label: "File Upload" },
    { value: "missed_call", label: "Missed Call" },
    { value: "estimate", label: "Estimate" },
    { value: "event", label: "Event" },
    { value: "rate", label: "Rate" },
];

export default function CreateTicketForm({ initialData, canDelete = true, onCancel, onSubmit, onDelete }: IFormsProps) {
    const [form] = Form.useForm();
    const [listInitStaffs, setListInitStaffs] = React.useState<IListStaffs[] | any>([]);
    const [selectAllStaffs, setSelectAllStaffs] = React.useState(false);

    const initialValues = {
        title: initialData.label,
        type_ticket: initialData?.type,
        staff_ids: initialData?.staff_ids ?? initialData?.staffIds,
        subject: initialData?.subject,
        description: initialData?.description,
    };

    const sumbit = (data: IFormInputs) => {
        onSubmit(data);
    };

    React.useEffect(() => {
        if (initialData.listStaffs && initialData.listStaffs.length > 0) {
            setListInitStaffs(
                initialData.listStaffs.map((staff: any) => ({
                    value: staff.id,
                    label: `${staff.firstName ?? ""} ${staff.lastName ?? ""}`,
                }))
            );
        }
    }, [initialData]);

    React.useEffect(() => {
        if (initialData && !isEmpty(initialData)) {
            form.setFieldValue("ticket_type", initialData?.ticket_type ?? initialData?.ticketType);
            form.setFieldValue("staff_ids", initialData?.staff_ids ?? initialData?.staffIds);
            form.setFieldValue("subject", initialData?.subject);
            form.setFieldValue("description", initialData?.description);
        }
    }, [initialData, form]);

    const handleSelectAllStaffs = (e: CheckboxChangeEvent) => {
        setSelectAllStaffs(e.target.checked);

        if (e.target.checked) {
            form.setFieldValue(
                "staff_ids",
                initialData.listStaffs.map((staff: any) => staff.id)
            );
        }
    };

    return (
        <div className="form-layout">
            <Form layout="vertical" form={form} initialValues={initialValues} onFinish={sumbit}>
                <Row gutter={16}>
                    <Col span={12}>
                        <Form.Item
                            label="Subject"
                            name="subject"
                            rules={[{ required: true, message: "Subject is required field" }]}
                        >
                            <Input placeholder="Subject" />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            label="Ticket type"
                            name="ticket_type"
                            rules={[{ required: true, message: "Ticket type is required field" }]}
                        >
                            <Select
                                className="field"
                                options={TICKET_TYPES?.map((type) => ({
                                    label: type.label,
                                    value: type.value,
                                }))}
                                placeholder="Select"
                                getPopupContainer={() =>
                                    document.getElementById("nodeSettingsPanelWrapper") as HTMLElement
                                }
                                style={{ width: "100%" }}
                            />
                        </Form.Item>
                    </Col>

                    <Col span={12} style={{ marginBottom: "24px" }}>
                        <Form.Item
                            label="Select staffs"
                            name="staff_ids"
                            rules={[{ required: true, message: "Staff is required field" }]}
                            style={{ marginBottom: "5px" }}
                        >
                            <Select
                                disabled={selectAllStaffs}
                                mode="multiple"
                                className="value"
                                options={listInitStaffs}
                                placeholder="Select"
                                getPopupContainer={() =>
                                    document.getElementById("nodeSettingsPanelWrapper") as HTMLElement
                                }
                                style={{ width: "100%" }}
                            />
                        </Form.Item>
                        <Checkbox value={selectAllStaffs} onChange={handleSelectAllStaffs}>
                            Select all staffs
                        </Checkbox>
                    </Col>
                    <Col span={24}>
                        <Form.Item label="Description" name="description">
                            <TextArea
                                defaultValue={initialData?.description || ""}
                                style={{ minHeight: "150px" }}
                                placeholder="Description"
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <FormFooter onCancel={onCancel} canDelete={canDelete} onDelete={onDelete} />
            </Form>
        </div>
    );
}
