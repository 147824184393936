import React from "react";

const useClickAway = (
    ref: React.RefObject<HTMLDivElement | undefined>,
    callback: (e: MouseEvent | TouchEvent) => void
) => {
    React.useEffect(() => {
        const handler = (e: MouseEvent | TouchEvent) => {
            const element = ref.current;
            if (element && !element.contains(e.target as Node)) {
                callback(e);
            }
        };

        document.addEventListener("mousedown", handler);
        document.addEventListener("touchstart", handler);

        return () => {
            document.removeEventListener("mousedown", handler);
            document.removeEventListener("touchstart", handler);
        };
    }, [ref, callback]);
};

export default useClickAway;
