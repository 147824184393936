import React from "react";

import { BaseEdge, EdgeLabelRenderer, SmoothStepEdgeProps, getSmoothStepPath } from "reactflow";
import SmoothStepButtonEdgeLabel from "./SmoothStepButtonEdgeLabel";

export default function SmoothStepButtonEdge({
    id,
    sourceX,
    sourceY,
    targetX,
    targetY,
    style,
    sourcePosition,
    targetPosition,
    markerEnd,
    pathOptions,
    data,
}: SmoothStepEdgeProps) {
    const [edgePath, labelX, labelY] = getSmoothStepPath({
        sourceX,
        sourceY,
        sourcePosition,
        targetX,
        targetY,
        targetPosition,
        borderRadius: pathOptions?.borderRadius,
        offset: pathOptions?.offset,
    });

    const onEdgeClick = (event: React.SyntheticEvent) => {
        event.stopPropagation();
        console.log(id);
    };

    return (
        <>
            <BaseEdge path={edgePath} markerEnd={markerEnd} style={style} />
            <EdgeLabelRenderer>
                {data && data?.showStartButton && (
                    <SmoothStepButtonEdgeLabel
                        transform={`translate(-50%, -50%) translate(${sourceX}px,${sourceY + 20}px)`}
                        onEdgeClick={onEdgeClick}
                    />
                )}
                {(!data || data?.showMiddleButton) && (
                    <SmoothStepButtonEdgeLabel
                        transform={`translate(-50%, -50%) translate(${labelX}px,${labelY}px)`}
                        onEdgeClick={onEdgeClick}
                    />
                )}
                {data && data?.showEndButton && (
                    <SmoothStepButtonEdgeLabel
                        transform={`translate(-50%, -50%) translate(${targetX}px,${targetY - 20}px)`}
                        onEdgeClick={onEdgeClick}
                    />
                )}
            </EdgeLabelRenderer>
        </>
    );
}
