import styled from "styled-components";

export const ConditionEditorWrapperStyled = styled.div`
    .bottom-btn-actions {
        display: flex;
        justify-content: end;

        .btn-add {
            margin-right: -15px;
        }
    }

    .condition-sets {
        .btn-add {
            margin-right: 24px;
            padding-right: 0;
        }
    }

    .branches {
        & > .bottom-btn-actions {
            padding: 16px 0 0 0;
        }
    }

    .conditions {
        .bottom-btn-actions {
            padding: 16px 0;
        }
    }

    .ant-form-item {
        margin-bottom: 0;
    }
`;
