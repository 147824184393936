import React from "react";

import { Typography, Input, Button, Form, message, UploadFile, Modal } from "antd";
import { EyeFilled, ThunderboltFilled, SendOutlined, PaperClipOutlined } from "@ant-design/icons";
import { RcFile } from "antd/es/upload";
import { shallow } from "zustand/shallow";

import { PreviewEmailTemplateWrapper } from "./PreviewEmailTemplate.styled";
import { IPreviewEmailTemplateProps } from "types";
import { evaluateVariable, parseDoubleBraces } from "utils/parseDoubleBraces";
import { useSendTestEmailMutation } from "hooks/features/templates/mutations";
import useStore, { RFState } from "stores";

interface IFormInputs {
    sendTo: string;
}

const { Title } = Typography;

const selector = (state: RFState) => ({
    conditionFields: state.conditionFields,
});

export default function PreviewEmailTemplate({ emailTemplate, visibleModal, onCancel }: IPreviewEmailTemplateProps) {
    const [messageApi, contextHolder] = message.useMessage();
    const [form] = Form.useForm();
    const sendTestEmailMutation = useSendTestEmailMutation();
    const { conditionFields } = useStore(selector, shallow);

    const renderVariableList = () => {
        if (!conditionFields || conditionFields.length === 0) {
            return <div>No variables</div>;
        }

        return conditionFields.map((templateTag) => (
            <div key={`tag-${templateTag.field}`} className="variable-item">
                <div className="variable-title">
                    <Title level={5}>{templateTag.field} </Title>
                </div>
                <div className="variable-data">{evaluateVariable(templateTag.field)}</div>
            </div>
        ));
    };

    const renderAttachmentFileList = () => {
        return emailTemplate?.fileList?.map((file, index) => (
            <div key={`index_${index}`} className="attachment-file">
                <span>
                    <PaperClipOutlined />
                </span>
                <span className="attachment-file-name">{file.name}</span>
            </div>
        ));
    };

    const sendTestEmail = async (data: IFormInputs) => {
        try {
            const formData = new FormData();
            formData.append("send_to", data.sendTo);
            formData.append("subject", parseDoubleBraces(emailTemplate?.subject ?? ""));
            formData.append("message", parseDoubleBraces(emailTemplate?.message));
            if (emailTemplate?.templateId) {
                formData.append("template_id", emailTemplate?.templateId.toString());
            } else if (emailTemplate?.fileList && emailTemplate?.fileList.length > 0) {
                emailTemplate?.fileList?.forEach((file: UploadFile) => {
                    formData.append("attachment_files[]", file as RcFile);
                });
            }

            await sendTestEmailMutation.mutateAsync({ formData });

            messageApi.open({
                type: "success",
                content: "The test email is sent.",
            });
        } catch (e) {
            console.log(e);
            messageApi.open({
                type: "error",
                content: "Cannot send test email.",
            });
        }
    };

    return (
        <Modal
            centered
            open={visibleModal}
            onCancel={onCancel}
            width={"80%"}
            getContainer={() => document.getElementById("nodeSettingsPanelWrapper") as HTMLElement}
            zIndex={99999}
            footer={
                <>
                    <Button key="back" onClick={onCancel}>
                        Cancel
                    </Button>
                    <Button
                        form="emailForm"
                        htmlType="submit"
                        key="submit"
                        type="primary"
                        loading={sendTestEmailMutation.isLoading}
                    >
                        <SendOutlined /> Send test email
                    </Button>
                </>
            }
            bodyStyle={{
                overflowX: "hidden",
                overflowY: "auto",
                maxHeight: "calc(100vh - 200px)",
                transformOrigin: "0 0",
            }}
        >
            <PreviewEmailTemplateWrapper id="previewEmailTemplateWrapper">
                {contextHolder}
                <div className="test-email-container">
                    <header className="variable-header">
                        <Title level={4}>
                            <ThunderboltFilled /> Variables
                        </Title>
                    </header>
                    <div className="variable-list">{renderVariableList()}</div>
                </div>
                <div className="email-preview-container">
                    <Form
                        id="emailForm"
                        layout="vertical"
                        form={form}
                        onFinish={(values: IFormInputs) => void sendTestEmail(values)}
                        scrollToFirstError={true}
                    >
                        <div className="email-preview-details">
                            <header>
                                <Title level={3}>
                                    <EyeFilled /> Email Preview
                                </Title>
                            </header>
                            <div className="email-preview-subject">
                                <Title level={5}>Send to:</Title>
                                <div>
                                    <Form.Item
                                        name="sendTo"
                                        rules={[
                                            { required: true, message: "Send To is required field" },
                                            { type: "email", message: "Send To is not a valid email" },
                                        ]}
                                    >
                                        <Input placeholder="Send To" />
                                    </Form.Item>
                                </div>
                            </div>
                            {emailTemplate?.subject && (
                                <div className="email-preview-subject">
                                    <Title level={5}>Subject:</Title>
                                    <div>{parseDoubleBraces(emailTemplate?.subject)}</div>
                                </div>
                            )}
                            <div className="email-preview-message">
                                <Title level={5}>Message:</Title>
                                <iframe
                                    title="Test Email"
                                    loading="lazy"
                                    srcDoc={parseDoubleBraces(emailTemplate?.message ?? "")}
                                />
                                {emailTemplate?.fileList && emailTemplate?.fileList.length > 0 && (
                                    <div className="email-attachment-files">{renderAttachmentFileList()}</div>
                                )}
                            </div>
                        </div>
                    </Form>
                </div>
            </PreviewEmailTemplateWrapper>
        </Modal>
    );
}
