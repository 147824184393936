import styled from "styled-components";

interface Props {
    isSelected?: boolean;
}

export const BranchWrapperStyled = styled.div<Props>`
    & > .ant-card {
        background-color: #fafafa;
        ${({ theme, isSelected }) =>
            isSelected &&
            `
        border: 1px solid ${theme.colors.primary};
    `}
    }

    .condition-sets {
        .ant-card {
            background-color: #fafafa;
            border: none;
        }

        .conditions {
            background-color: #e2e8f0;
            .ant-card {
                background-color: #e2e8f0;
            }
        }

        .btn-actions {
            justify-content: space-between;
            align-items: center;
        }
    }

    .btn-actions {
        width: 100%;
        display: flex;
        justify-content: end;

        .btn-delete {
            margin-right: -5px;
        }
    }

    .branch-name-input {
        margin-top: 24px;
        margin-left: 24px;
        margin-right: 24px;
    }
`;
