import { IFile } from "types";
import api from "./axios";

const removeFile = async (fileId: number) => {
    const response = await api.delete<IFile, IFile>(`/api/files/${fileId}`);

    return response;
};

export const FileAPI = {
    removeFile,
};
