import styled from "styled-components";

export const Subnav = styled.div`
    border-bottom: 1px solid #e8e8ef;
    width: calc(100% + 20px);
    margin-left: -20px;
    margin-top: 10px;
    display: flex;
`;

export const Nav = styled.div`
    font-weight: 500;
    font-size: 14px;
    text-align: center;
    color: #808292;
    width: calc(88% / 3);
    height: 48px;
    line-height: 48px;
    flex: 1;

    &:first-of-type {
        margin-left: 20px;
    }
    &:last-of-type {
        margin-right: 20px;
    }

    &.navactive {
        color: #393c44 !important;
    }

    &.navactive::after {
        display: block;
        content: "";
        width: 100%;
        height: 4px;
        background-color: #217ce8;
        margin-top: -4px;
    }

    &.navdisabled {
        transition: all 0.3s cubic-bezier(0.05, 0.03, 0.35, 1);
    }

    &.navdisabled:hover {
        cursor: pointer;
        opacity: 0.5;
    }
`;

export const BlockListStyled = styled.div`
    height: calc(100% - 220px);
    overflow: auto;

    .blockelem:first-child {
        margin-top: 20px;
    }

    .blockelem {
        padding-top: 10px;
        width: 266px;
        border: 1px solid transparent;
        transition-property: box-shadow, height;
        transition-duration: 0.2s;
        transition-timing-function: cubic-bezier(0.05, 0.03, 0.35, 1);
        border-radius: 5px;
        box-shadow: 0 0 30px rgb(22 33 74 / 0%);
        box-sizing: border-box;
    }

    .blockelem:hover {
        box-shadow: 0 4px 30px rgb(22 33 74 / 8%);
        border-radius: 5px;
        background-color: #fff;
        cursor: pointer;
    }

    .grabme,
    .blockico {
        display: inline-block;
    }

    .grabme {
        margin-top: 10px;
        margin-left: 10px;
        margin-bottom: -14px;
        width: 12px;

        img {
            width: 9px;
        }
    }

    .blockin {
        display: inline-block;
        vertical-align: top;
        margin-left: 12px;
    }

    .blockico {
        width: 30px;
        height: 30px;
        background-color: #f1f4fc;
        border-radius: 5px;
        /* text-align: center; */
        white-space: nowrap;
        padding: 4.5px;

        span {
            height: 100%;
            width: 0;
            display: inline-block;
            vertical-align: middle;
        }

        img {
            vertical-align: middle;
            margin-left: auto;
            margin-right: auto;
            display: inline-block;
            width: 22px;
            height: auto;
        }
    }

    .blocktext {
        display: inline-block;
        width: 168px;
        vertical-align: top;
        margin-left: 12px;
    }

    .blocktitle {
        margin: 0 !important;
        padding: 0 !important;
        font-weight: 500;
        font-size: 14px;
        color: #393c44;
    }

    .blockdesc {
        margin-top: 5px;
        color: #808292;
        font-size: 12px;
        line-height: 21px;
    }

    .blockdisabled {
        background-color: #f0f2f9;
        opacity: 0.5;
    }

    .spinner-wrapper {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
`;
