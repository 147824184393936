import React from "react";

const useBeforeUnbound = (handler: () => boolean, message?: string) => {
    const callback = React.useCallback(
        (event: BeforeUnloadEvent) => {
            if (!handler()) {
                return;
            }

            event.preventDefault();

            if (message) {
                // eslint-disable-next-line no-param-reassign
                event.returnValue = "";
            }

            return message;
        },
        [handler, message]
    );

    React.useEffect(() => {
        window.addEventListener("beforeunload", callback);

        return () => {
            window.removeEventListener("beforeunload", callback);
        };
    }, [callback]);
};

export default useBeforeUnbound;
