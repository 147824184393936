/* eslint-disable @typescript-eslint/no-misused-promises */
import React from "react";

import { Col, Form, Input, Row, Select } from "antd";
import { isEmpty } from "lodash-es";
import { shallow } from "zustand/shallow";

import { IFormsProps } from "../../../types";
import FormFooter from "../FormFooter";
import useStore, { RFState } from "../../../stores";
import { useGetConditionFieldValuesQuery } from "hooks/features/workflows/queries";
import { IConditionField } from "types";

interface IFormInputs {
    title: string;
    field: string;
    value: string;
}

const selector = (state: RFState) => ({
    conditionFields: state.conditionFields,
});

export default function ContactUpdateForm({
    initialData,
    canDelete = true,
    onCancel,
    onSubmit,
    onDelete,
}: IFormsProps) {
    const { conditionFields } = useStore(selector, shallow);
    const [form] = Form.useForm();
    const initialValues = {
        title: initialData.label,
        field: String(initialData?.field ?? ""),
        value: String(initialData?.value ?? ""),
    };
    const [currentConditionField, setCurrentConditionField] = React.useState<IConditionField>();
    const fieldWatch = Form.useWatch("field", form);
    const [conditionFieldValueOptions, setConditionFieldValueOptions] = React.useState<
        { label: string; value: string }[]
    >([]);
    const { data: conditionFieldValueData, isLoading: isConditionFieldValueLoading } = useGetConditionFieldValuesQuery(
        currentConditionField?.id ?? 0,
        currentConditionField?.type === "select"
    );

    const sumbit = (data: IFormInputs) => {
        onSubmit(data);
    };

    const renderConditionFieldValueInput = () => {
        if (currentConditionField) {
            if (currentConditionField.type === "select") {
                return (
                    <Form.Item
                        label="Value"
                        name={"value"}
                        rules={[{ required: true, message: "Value is required field" }]}
                    >
                        <Select
                            className="value"
                            options={conditionFieldValueOptions}
                            placeholder="Select"
                            getPopupContainer={() => document.getElementById("nodeSettingsPanelWrapper") as HTMLElement}
                            style={{ width: "100%" }}
                        />
                    </Form.Item>
                );
            }

            return (
                <Form.Item
                    label="Value"
                    name={"value"}
                    rules={[{ required: true, message: "Value is required field" }]}
                >
                    <Input className="value" placeholder="Value" style={{ width: "100%" }} />
                </Form.Item>
            );
        }
    };

    React.useEffect(() => {
        if (initialData && !isEmpty(initialData)) {
            form.setFieldValue("field", String(initialData?.field ?? ""));
            form.setFieldValue("value", String(initialData?.value ?? ""));
        }
    }, [initialData, form]);

    React.useEffect(() => {
        if (fieldWatch && fieldWatch !== currentConditionField?.field) {
            const conditionField = conditionFields.find((cf) => cf.field === fieldWatch);
            if (conditionField && !isEmpty(conditionField)) {
                setCurrentConditionField(conditionField);
                form.resetFields(["value"]);
            }
        }
    }, [fieldWatch, conditionFields]);

    React.useEffect(() => {
        if (conditionFieldValueData && !isEmpty(conditionFieldValueData) && !isConditionFieldValueLoading) {
            setConditionFieldValueOptions(
                conditionFieldValueData.map((item) => ({
                    label: String(item),
                    value: String(item),
                }))
            );
        }
    }, [conditionFieldValueData, isConditionFieldValueLoading]);

    return (
        <div className="form-layout">
            <Form layout="vertical" form={form} initialValues={initialValues} onFinish={sumbit}>
                <Row gutter={16}>
                    <Col span={12}>
                        <Form.Item
                            label="Field"
                            name="field"
                            rules={[{ required: true, message: "Field is required field" }]}
                        >
                            <Select
                                className="field"
                                options={conditionFields?.map((conditionField) => ({
                                    label: conditionField.label,
                                    value: conditionField.field,
                                }))}
                                placeholder="Select"
                                getPopupContainer={() =>
                                    document.getElementById("nodeSettingsPanelWrapper") as HTMLElement
                                }
                                style={{ width: "100%" }}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={12}>{renderConditionFieldValueInput()}</Col>
                </Row>
                <FormFooter onCancel={onCancel} canDelete={canDelete} onDelete={onDelete} />
            </Form>
        </div>
    );
}
