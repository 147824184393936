import React from "react";

import { Button } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { ISmoothStepButtonEdgeLabelProps } from "types";

export default function SmoothStepButtonEdgeLabel({ transform, onEdgeClick }: ISmoothStepButtonEdgeLabelProps) {
    return (
        <div
            style={{
                position: "absolute",
                transform,
                fontSize: 12,
                pointerEvents: "all",
            }}
            className="nodrag nopan"
        >
            <Button className="edgebutton" onClick={(event) => onEdgeClick(event)}>
                <PlusOutlined />
            </Button>
        </div>
    );
}
