import { AnimatePresence, motion } from "framer-motion";

import { SidebarStyled } from "./Sidebar.styled";
import CloseLeft from "../../assets/closeleft.svg";
import Blocks from "../Blocks/Blocks";
import { ISidebarProps } from "../../types";
import SearchInput from "components/SearchInput/SearchInput";

const Sidebar = ({ onDrag, onDragStart, onDragEnd, onDragOver, collapsed = false, onToggleSidebar }: ISidebarProps) => {
    const handleOnSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
        console.log(e.target.value);
    };

    return (
        <AnimatePresence>
            <SidebarStyled
                initial={{ width: 311, paddingLeft: 20 }}
                animate={{
                    width: collapsed ? 0 : 311,
                    paddingLeft: collapsed ? 0 : 20,
                    transition: { delay: 0, duration: 0.3 },
                }}
                onDragOver={onDragOver}
            >
                <motion.div
                    className="closecard"
                    onClick={onToggleSidebar}
                    initial={{
                        marginLeft: 288,
                    }}
                    animate={{
                        marginLeft: collapsed ? 0 : 288,
                        transition: { delay: 0, duration: 0.3 },
                    }}
                    aria-hidden
                >
                    <motion.img
                        src={CloseLeft}
                        alt="Close Left"
                        animate={{
                            rotate: collapsed ? 180 : 0,
                            transition: { delay: 0, duration: 0.3 },
                        }}
                    />
                </motion.div>
                {!collapsed && (
                    <motion.div
                        className="sidebar__container"
                        initial={{ opacity: 0 }}
                        animate={{
                            opacity: collapsed ? 0 : 1,
                            transition: { delay: 0.1, duration: 0.5 },
                        }}
                    >
                        <SearchInput onSearch={handleOnSearch} />
                        <Blocks onDrag={onDrag} onDragStart={onDragStart} onDragEnd={onDragEnd} />
                    </motion.div>
                )}
            </SidebarStyled>
        </AnimatePresence>
    );
};

export default Sidebar;
