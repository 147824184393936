import api from "./axios";

const getCSRFToken = async () => {
    const response = await api.get("/sanctum/csrf-cookie");

    return response;
};

const getAuthProfile = async () => {
    const response = await api.get("/api/user");

    return response;
};

export const AuthAPI = {
    getCSRFToken,
    getAuthProfile,
};
