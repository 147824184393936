/* eslint-disable @typescript-eslint/no-misused-promises */
import React from "react";

import { Form, Input, Select, Switch } from "antd";
import { omit } from "lodash-es";

import { IFormsProps } from "../../../types";
import FormFooter from "../FormFooter";

interface IFormInputs {
    title: string;
    status: string;
}

export default function AssignToUserForm({ initialData, canDelete = true, onCancel, onSubmit, onDelete }: IFormsProps) {
    const [form] = Form.useForm();
    const initialValues = {
        title: initialData.label,
        status: String(initialData?.status ?? ""),
    };

    const sumbit = (data: IFormInputs) => {
        console.log(data);
        onSubmit({
            ...omit(data, ["title"]),
            label: data.title,
        });
    };

    return (
        <div className="form-layout">
            <Form layout="vertical" form={form} initialValues={initialValues} onFinish={sumbit}>
                <Form.Item
                    label="Action Name"
                    name="title"
                    rules={[{ required: true, message: "Action Name is required field" }]}
                >
                    <Input placeholder="Action Name" />
                </Form.Item>
                <Form.Item label="User" name="user" rules={[{ required: true, message: "User is required field" }]}>
                    <Select
                        className="status"
                        options={[
                            { value: "user1", label: "User 1" },
                            { value: "user2", label: "User 2" },
                        ]}
                        placeholder="Select"
                    />
                </Form.Item>
                <Form.Item label="Apply To Unassigned Contacts" name="applyToUnassigendContacts">
                    <Switch />
                </Form.Item>
                <FormFooter onCancel={onCancel} canDelete={canDelete} onDelete={onDelete} />
            </Form>
        </div>
    );
}
