import styled from "styled-components";

interface IProps {
    fullscreen: boolean;
}

export const SpinnerWrapper = styled.div<IProps>`
    ${({ fullscreen }) =>
        fullscreen &&
        `
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100vh;
    `}
`;
