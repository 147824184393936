import { create } from "zustand";
import { immer } from "zustand/middleware/immer";

import { IBranch, IBranches, ICondition } from "types";

export type ConditionEditorState = {
    branches: IBranches;
};

export type ConditionEditorActions = {
    setBranches: (branches: IBranches) => void;
    addBranch: (branch: IBranch) => void;
    onBranchChange: (branchId: string, name: string) => void;
    deleteBranch: (id: string) => void;
    addConditionSet: (branchId: string, conditionSet: ICondition[]) => void;
    deleteConditionSet: (branchId: string, conditionSetIndex: number) => void;
    addCondition: (branchId: string, conditionSetIndex: number, condition: ICondition) => void;
    deleteCondition: (branchId: string, conditionSetIndex: number, conditionIndex: number) => void;
    onConditionChange: (
        branchId: string,
        conditionSetIndex: number,
        conditionIndex: number,
        fieldName: string,
        value: any
    ) => void;
    reset: () => void;
};

const initialState: ConditionEditorState = {
    branches: [],
};

const useConditionEditorStore = create(
    immer<ConditionEditorState & ConditionEditorActions>((set) => ({
        ...initialState,
        setBranches: (branches: IBranches) =>
            set((state) => {
                state.branches = branches;
            }),
        addBranch: (branch) =>
            set((state) => {
                state.branches.push(branch);
            }),
        onBranchChange: (branchId: string, name: string) =>
            set((state) => {
                const branches = state.branches;
                const branchIndex = branches.findIndex((branch) => branch.id === branchId);
                branches[branchIndex].name = name;
            }),
        deleteBranch: (id) =>
            set((state) => {
                state.branches = state.branches.filter((branch) => branch.id !== id);
            }),
        addConditionSet: (branchId: string, conditionSet: ICondition[]) =>
            set((state) => {
                const branches = state.branches;
                const branchIndex = branches.findIndex((branch) => branch.id === branchId);
                if (branchIndex !== -1) {
                    if (!branches?.[branchIndex]?.conditionSets) {
                        branches[branchIndex].conditionSets = [];
                    }
                    branches[branchIndex].conditionSets.push(conditionSet);
                }
            }),
        deleteConditionSet: (branchId: string, conditionSetIndex: number) =>
            set((state) => {
                const branches = state.branches;
                const branchIndex = branches.findIndex((branch) => branch.id === branchId);
                branches[branchIndex].conditionSets.splice(conditionSetIndex, 1);
            }),
        addCondition: (branchId: string, conditionSetIndex: number, condition: ICondition) =>
            set((state) => {
                const branches = state.branches;
                const branchIndex = branches.findIndex((branch) => branch.id === branchId);
                branches[branchIndex].conditionSets[conditionSetIndex].push(condition);
            }),
        deleteCondition: (branchId: string, conditionSetIndex: number, conditionIndex: number) =>
            set((state) => {
                const branches = state.branches;
                const branchIndex = branches.findIndex((branch) => branch.id === branchId);
                branches[branchIndex].conditionSets[conditionSetIndex].splice(conditionIndex, 1);
            }),
        onConditionChange: (
            branchId: string,
            conditionSetIndex: number,
            conditionIndex: number,
            fieldName: string,
            value: any
        ) =>
            set((state) => {
                const branches = state.branches;
                const branchIndex = branches.findIndex((branch) => branch.id === branchId);
                branches[branchIndex].conditionSets[conditionSetIndex][conditionIndex][fieldName as keyof ICondition] =
                    value;
            }),
        reset: () =>
            set(() => {
                set(initialState);
            }),
    }))
);

export default useConditionEditorStore;
