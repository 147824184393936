import ReactFlow from "reactflow";
import styled from "styled-components";
import { ICanvasStyledProps } from "types/styledComponents";

export const WorkflowLayoutStyled = styled.div`
    .container {
        display: flex;
        flex-direction: row;
    }
`;

export const ReactFlowStyled = styled(ReactFlow)`
    .react-flow__node {
        cursor: pointer;
    }

    .react-flow__handle {
        width: 8px;
        height: 8px;
    }

    .react-flow__handle-top {
        top: -5px;
    }

    .react-flow__handle-bottom {
        bottom: -5px;
    }

    .show-bottom-handle {
        .react-flow__handle-bottom {
            opacity: 1;
        }
    }
`;

export const CanvasStyled = styled.div<ICanvasStyledProps>`
    flex: 1 1 auto;
    margin-top: ${({ isPreviewMode }) => (isPreviewMode ? "0px" : "71px")};
    height: ${({ isPreviewMode }) => (isPreviewMode ? "100vh" : "calc(100vh - 71px)")};
    z-index: 0;
    overflow: auto;
`;
