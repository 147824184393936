import styled from "styled-components";

export const FormFooterWrapper = styled.div`
    border-top: 1px solid #e8e8ef;
    display: flex;
    justify-content: space-between;
    padding-top: 24px;
    padding-bottom: 24px;
    position: absolute;
    bottom: 0;
    width: calc(100% - 40px);
    background-color: rgb(255, 255, 255);

    .left-actions {
        .ant-btn {
            margin-right: 8px;
        }
    }

    .right-actions {
        display: flex;
        justify-content: right;
        .ant-btn {
            margin-left: 8px;
        }
    }
`;
