import { createGlobalStyle } from "styled-components";
import { IbmFontSans } from "./fonts";

// Uses antd/dist/reset.css to reset some basic styles.
export const GlobalStyles = createGlobalStyle`
    ${IbmFontSans} 
    /* Box sizing rules */
    *,
    *::before,
    *::after {
        box-sizing: border-box;
    }
    html {
        -webkit-text-size-adjust: 100%;
        -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
        scroll-behavior: smooth;
        height: 100%;
    }
    body {
        margin: 0;
        padding: 0;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale ;
        font-family: ${({ theme }) => theme.fonts.body};
    }

    // Custom antd styles.

    // Disable text-overflow in select options.
    .ant-select-item-option-content {
        white-space: break-spaces !important;
        word-break: break-word !important;
    }
`;
