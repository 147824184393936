import React from "react";

import { Form } from "antd";

import { omit } from "lodash-es";

import { IBranches, IFormsProps } from "../../../types";
import ConditionEditor from "components/ConditionEditor";
import FormFooter from "../FormFooter";

interface IFormInputs {
    title: string;
    branches: IBranches;
}

export default function BranchForm({ initialData, canDelete = true, onCancel, onSubmit, onDelete }: IFormsProps) {
    const [form] = Form.useForm();

    const sumbit = (data: IFormInputs) => {
        onSubmit({
            ...omit(data, "branchList"),
        });
    };

    React.useEffect(() => {
        if (initialData?.branches) {
            const branchFormData = initialData?.branches
                ?.filter((branch: any) => !branch?.isDefault)
                .map((branch: any) => {
                    return {
                        name: branch?.name ?? "",
                        conditionSets: branch?.conditionSets.map((conditionSet: any) => {
                            return {
                                conditions: conditionSet.map((condition: any) => {
                                    if (!condition?.field) {
                                        return {};
                                    }

                                    return {
                                        ...condition,
                                    };
                                }),
                            };
                        }),
                    };
                });

            form.setFieldValue("branchList", branchFormData);
        }
    }, [initialData]);

    return (
        <div className="form-layout">
            <Form layout="vertical" form={form} onFinish={sumbit}>
                {initialData?.branches && (
                    <Form.Item label="Branches" name="branches">
                        <ConditionEditor
                            initialValues={initialData?.branches ?? []}
                            selectedBranch={initialData?.selectedBranch}
                        />
                    </Form.Item>
                )}
                <FormFooter onCancel={onCancel} canDelete={canDelete} onDelete={onDelete} />
            </Form>
        </div>
    );
}
