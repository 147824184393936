import React from "react";

import { v4 as uuidv4 } from "uuid";
import { Button, Card, Space, Form, FormListOperation } from "antd";
import { PlusCircleOutlined, CloseCircleOutlined } from "@ant-design/icons";

import { ICondition, IConditionSetsProps } from "types";
import useConditionEditorStore from "stores/useConditionEditorStore";
import Condition from "../Condition";

export default function ConditionSets({
    branchId,
    conditionSets,
    conditionSetIndex,
    isDefault = false,
    field,
    remove,
}: IConditionSetsProps) {
    const { deleteConditionSet, addCondition } = useConditionEditorStore();

    const handleAddCondition = (subOpt: FormListOperation) => {
        const newCondition = {
            id: uuidv4(),
        };
        addCondition(branchId, Number(conditionSetIndex), newCondition);
        subOpt.add();
    };

    const handleDeleteConditionSet = () => {
        deleteConditionSet(branchId, Number(conditionSetIndex));
        remove(field.name);
    };

    const renderCondtions = (conditions: ICondition[]) => {
        return (
            <Form.Item>
                <Form.List name={[field.name, "conditions"]}>
                    {(conditionFields, conditionOpt) => (
                        <div className="conditions">
                            {conditionFields.map((conditionField, conditionIndex) => {
                                const condition = conditions[conditionIndex];

                                return (
                                    <Space
                                        direction="vertical"
                                        size={16}
                                        style={{ width: "100%" }}
                                        key={conditionField.key}
                                    >
                                        <Condition
                                            key={`condition_${conditionField.key}`}
                                            branchId={branchId}
                                            conditionSetIndex={conditionSetIndex}
                                            conditionIndex={conditionIndex}
                                            condition={condition}
                                            canDelete={conditions.length > 1}
                                            isDefault={isDefault}
                                            field={conditionField}
                                            remove={conditionOpt.remove}
                                        />
                                    </Space>
                                );
                            })}
                            {!isDefault && (
                                <div className="bottom-btn-actions">
                                    <Button
                                        className="btn-add"
                                        icon={<PlusCircleOutlined />}
                                        type="link"
                                        onClick={() => handleAddCondition(conditionOpt)}
                                    >
                                        Add condition
                                    </Button>
                                </div>
                            )}
                        </div>
                    )}
                </Form.List>
            </Form.Item>
        );
    };

    const renderConditionSets = () => {
        const conditionSet = conditionSets[conditionSetIndex];

        return (
            <Card className={`condition-set ${conditionSetIndex}`} key={`${branchId}_${conditionSetIndex}`}>
                {conditionSets.length > 1 && (
                    <div className="btn-actions">
                        <div>{conditionSetIndex >= 1 && <span>OR</span>}</div>
                        <Button
                            className="btn-delete"
                            icon={<CloseCircleOutlined />}
                            onClick={() => handleDeleteConditionSet()}
                            danger
                            type="link"
                        ></Button>
                    </div>
                )}
                {renderCondtions(conditionSet)}
            </Card>
        );
    };

    return (
        <Space direction="vertical" size={16} style={{ width: "100%" }}>
            {renderConditionSets()}
        </Space>
    );
}
