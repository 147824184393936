import React from "react";

import type { MenuProps } from "antd";
import { Button, Dropdown } from "antd";
import { shallow } from "zustand/shallow";
import { TagOutlined } from "@ant-design/icons";

import useStore, { RFState } from "stores";

interface IProps {
    fieldName: string;
    only?: string[];
    onSelect: (fieldName: string, conditionField: string) => void;
}

const selector = (state: RFState) => ({
    conditionFields: state.conditionFields,
});

const TemplateTagDropdown = ({ fieldName, only = [], onSelect }: IProps) => {
    const { conditionFields } = useStore(selector, shallow);

    const getTagList = () => {
        const items: MenuProps["items"] = conditionFields
            .filter((conditionField) => {
                if (only && only.length > 0) {
                    return only.findIndex((o) => o === conditionField.field) !== -1;
                }

                return conditionField.type !== "select";
            })
            .map((conditionField) => ({
                label: (
                    <Button type="link" onClick={() => onSelect(fieldName, conditionField.field)}>
                        {conditionField.label}
                    </Button>
                ),
                key: conditionField.field,
            }));

        return items;
    };

    return (
        <>
            <Dropdown
                menu={{ items: getTagList() }}
                trigger={["click"]}
                getPopupContainer={() => document.getElementById("nodeSettingsPanelWrapper") as HTMLElement}
            >
                <TagOutlined style={{ color: "rgba(0,0,0,.45)", fontSize: "16px", cursor: "pointer" }} />
            </Dropdown>
        </>
    );
};

export default TemplateTagDropdown;
