import styled from "styled-components";

export const WorkflowSettingsWrapper = styled.div`
    flex: 1 1 auto;
    margin-top: 71px;
    margin-left: 20px;
    margin-right: 20px;
    height: calc(100vh - 71px);
    z-index: 0;
    overflow: auto;

    .ant-layout-content {
        padding: 24px;
    }
    .excluded-workflow-ids {
        display: flex;
        flex-direction: column;
        align-items: normal;
    }
`;
