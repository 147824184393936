import React from "react";

import { v4 as uuidv4 } from "uuid";
import { Button, Space, Form, FormListFieldData } from "antd";
import { PlusCircleOutlined } from "@ant-design/icons";
import { isEmpty } from "lodash-es";

import useConditionEditorStore from "stores/useConditionEditorStore";
import { ICondition, IConditionEditorProps } from "types";
import Branch from "./Branch/Branch";
import { ConditionEditorWrapperStyled } from "./ConditionEditor.styled";

export default function ConditionEditor({
    initialValues = [],
    onChange,
    selectedBranch,
    isMultipleBranches = false,
}: IConditionEditorProps) {
    const { branches, setBranches, addBranch } = useConditionEditorStore();

    const handleAddBranch = (add: (defaultValue?: any, insertIndex?: number) => void) => {
        const newCondition: ICondition = {
            id: uuidv4(),
        };

        addBranch({
            id: uuidv4(),
            name: "condition",
            isDefault: false,
            conditionSets: [[newCondition]],
        });

        add({
            name: "condition",
            conditionSets: [
                {
                    conditions: [{}],
                },
            ],
        });
    };

    const renderBranches = (fields: FormListFieldData[], remove: (index: number | number[]) => void) => {
        return (
            <>
                {fields.map((field, index) => {
                    const branch = branches?.filter((branchItem) => !branchItem?.isDefault)?.[index];

                    const canDelete =
                        branches?.filter((branchItem) => !branchItem?.isDefault).length > 1 &&
                        !(branch?.isDefault ?? false);

                    return (
                        <Branch
                            key={field.key}
                            branch={branch}
                            canDelete={canDelete}
                            isSelected={branch.id === selectedBranch}
                            isDefault={branch?.isDefault}
                            field={field}
                            remove={remove}
                        />
                    );
                })}
            </>
        );
    };

    React.useEffect(() => {
        if (initialValues && !isEmpty(initialValues)) {
            setBranches(initialValues);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [initialValues]);

    React.useEffect(() => {
        if (onChange) {
            onChange(branches);
        }
    }, [branches, onChange]);

    return (
        <ConditionEditorWrapperStyled>
            <Form.List name="branchList">
                {(fields, { add, remove }) => (
                    <div className="branches" style={{ marginBottom: "20px" }}>
                        <Space direction="vertical" size={16} style={{ width: "100%" }}>
                            {renderBranches(fields, remove)}
                        </Space>
                        {isMultipleBranches && (
                            <div className="bottom-btn-actions">
                                <div className="btn-add">
                                    <Button
                                        icon={<PlusCircleOutlined />}
                                        type="link"
                                        onClick={() => handleAddBranch(add)}
                                    >
                                        Add branch
                                    </Button>
                                </div>
                            </div>
                        )}
                    </div>
                )}
            </Form.List>
        </ConditionEditorWrapperStyled>
    );
}
