import React from "react";

import Cookies from "js-cookie";

import { AuthAPI } from "apis/authentication";

const useCSRFToken = () => {
    const [loggedIn, setLoggedIn] = React.useState<boolean>(false);
    const csrf = async () => {
        try {
            await AuthAPI.getCSRFToken();
            await AuthAPI.getAuthProfile();
            setLoggedIn(true);
        } catch (e) {
            console.log(e);
            setLoggedIn(false);
        }
    };

    React.useEffect(() => {
        if (!Cookies.get("XSRF-TOKEN")) {
            window.location.href = `${
                process.env.REACT_APP_DASHBOARD_BASE_URL || "https://dashboard.pilotpractice.com"
            }/login`;
            return;
        }
        csrf();
    }, []);

    return { loggedIn };
};

export default useCSRFToken;
