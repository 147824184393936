import { TConditionFieldValueResponse, IWorkflowResponse, TCommonPagination } from "types";
import api from "./axios";

export interface Trigger {
    id: number;
    name: string;
    type: string;
    conditionFields: any;
    description: string;
    triggerable: string;
    createdAt: Date;
    updatedAt: Date;
}

export type GetTriggerResponse = Trigger[];

const getTriggers = async () => {
    const response = await api.get<GetTriggerResponse, GetTriggerResponse>("/api/workflow/triggers");

    return response;
};

const createWorkflow = async (params: IWorkflowResponse) => {
    const response = await api.post<IWorkflowResponse, IWorkflowResponse>("/api/workflows", params);

    return response;
};

const getWorkflowsByIds = async (params: { ids?: number[] }) => {
    const response = await api.get<TCommonPagination<IWorkflowResponse>, TCommonPagination<IWorkflowResponse>>(
        `/api/workflows?ids=${params.ids}`
    );

    return response;
};

const getWorkflows = async (params: { page: number }) => {
    const response = await api.get<TCommonPagination<IWorkflowResponse>, TCommonPagination<IWorkflowResponse>>(
        `/api/workflows?page=${params.page}`
    );

    return response;
};

const getWorkflowById = async (id: number) => {
    const response = await api.get<IWorkflowResponse, IWorkflowResponse>(`/api/workflows/${id}`);

    return response;
};

const updateWorkflow = async (id: number, params: IWorkflowResponse) => {
    const response = await api.put<IWorkflowResponse, IWorkflowResponse>(`/api/workflows/${id}`, params);

    return response;
};

const getConditionFieldValues = async (conditionField: number) => {
    const response = await api.get<TConditionFieldValueResponse, TConditionFieldValueResponse>(
        `/api/workflow/condition-field/${conditionField}/values`
    );

    return response;
};

export const WorkflowAPI = {
    getTriggers,
    createWorkflow,
    getWorkflows,
    getWorkflowById,
    getWorkflowsByIds,
    updateWorkflow,
    getConditionFieldValues,
};
