import React from "react";
import { NodeProps, Handle, Position } from "reactflow";

import KloudMDNodeHeader from "../../KloudMDNodeHeader";
import { KloudMDNodeStyled } from "../KloudMDNode.styled";

const KloudMDDefaultNode = ({ id, data, selected, isConnectable }: NodeProps) => {
    return (
        <KloudMDNodeStyled selected={selected} isValid={data?.isValid}>
            <Handle type="target" position={Position.Top} isConnectable={isConnectable} />
            <div className="block-container">
                <KloudMDNodeHeader id={id} data={data} />
            </div>
            <Handle type="source" position={Position.Bottom} isConnectable={isConnectable} />
        </KloudMDNodeStyled>
    );
};

KloudMDDefaultNode.displayName = "KloudMDDefaultNode";

export default React.memo(KloudMDDefaultNode);
