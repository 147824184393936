/* eslint-disable @typescript-eslint/no-misused-promises */
import React from "react";

import { Col, Form, Row, Select } from "antd";
import { isEmpty } from "lodash-es";

import { IFormsProps } from "../../../types";
import FormFooter from "../FormFooter";

interface IFormInputs {
    staff_ids: number[];
    assignment_type: string;
}

interface IListStaffs {
    label: string;
    value: number;
}

const ASSIGNMENT_TYPE = [{ value: "round_robin", label: "Round Robin" }];

export default function AssignLeadForm({ initialData, canDelete = true, onCancel, onSubmit, onDelete }: IFormsProps) {
    const [form] = Form.useForm();
    const [listInitStaffs, setListInitStaffs] = React.useState<IListStaffs[] | any>([]);

    const initialValues = {
        title: initialData.label,
        field: initialData?.assignment_type ?? initialData?.assignmentType,
        value: initialData?.staff_ids ?? initialData?.staffIds,
    };

    const sumbit = (data: IFormInputs) => {
        onSubmit(data);
    };

    React.useEffect(() => {
        if (initialData.listStaffs && initialData.listStaffs.length > 0) {
            setListInitStaffs(
                initialData.listStaffs.map((staff: any) => ({
                    value: staff.id,
                    label: `${staff.firstName ?? ""} ${staff.lastName ?? ""}`,
                }))
            );
        }
    }, [initialData]);

    React.useEffect(() => {
        if (initialData && !isEmpty(initialData)) {
            form.setFieldValue("assignment_type", initialData?.assignment_type ?? initialData?.assignmentType);
            form.setFieldValue("staff_ids", initialData?.staff_ids ?? initialData?.staffIds);
        }
    }, [initialData, form]);

    return (
        <div className="form-layout">
            <Form layout="vertical" form={form} initialValues={initialValues} onFinish={sumbit}>
                <Row gutter={16}>
                    <Col span={12}>
                        <Form.Item
                            label="Assignment type"
                            name="assignment_type"
                            rules={[{ required: true, message: "Assignment type is required field" }]}
                        >
                            <Select
                                className="field"
                                options={ASSIGNMENT_TYPE?.map((type) => ({
                                    label: type.label,
                                    value: type.value,
                                }))}
                                placeholder="Select"
                                getPopupContainer={() =>
                                    document.getElementById("nodeSettingsPanelWrapper") as HTMLElement
                                }
                                style={{ width: "100%" }}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            label="Select staffs"
                            name="staff_ids"
                            rules={[{ required: true, message: "Staff is required field" }]}
                        >
                            <Select
                                mode="multiple"
                                className="value"
                                options={listInitStaffs}
                                placeholder="Select"
                                getPopupContainer={() =>
                                    document.getElementById("nodeSettingsPanelWrapper") as HTMLElement
                                }
                                style={{ width: "100%" }}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <FormFooter onCancel={onCancel} canDelete={canDelete} onDelete={onDelete} />
            </Form>
        </div>
    );
}
