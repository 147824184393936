/* eslint-disable @typescript-eslint/no-misused-promises */
import React from "react";

import { Form, Input } from "antd";
import { omit } from "lodash-es";

import { IFormsProps } from "../../../types";
import TinyMCEEditor from "../../TinyMCEEditor/TinyMCEEditor";
import FormFooter from "../FormFooter";

interface IFormInputs {
    title: string;
    note: string;
}

export default function CreateNoteForm({ initialData, canDelete = true, onCancel, onSubmit, onDelete }: IFormsProps) {
    const [form] = Form.useForm();
    const initialValues = {
        title: initialData.label,
        note: String(initialData?.note ?? ""),
    };

    const sumbit = (data: IFormInputs) => {
        console.log(data);
        onSubmit({
            ...omit(data, ["title"]),
            label: data.title,
        });
    };

    return (
        <div className="form-layout">
            <Form layout="vertical" form={form} initialValues={initialValues} onFinish={sumbit}>
                <Form.Item
                    label="Action Name"
                    name="title"
                    rules={[{ required: true, message: "Action Name is required field" }]}
                >
                    <Input placeholder="Action Name" />
                </Form.Item>
                <Form.Item name="note" label="Note" rules={[{ required: true, message: "Note is required field" }]}>
                    <TinyMCEEditor menubar="" toolbar="undo redo" />
                </Form.Item>
                <FormFooter onCancel={onCancel} canDelete={canDelete} onDelete={onDelete} />
            </Form>
        </div>
    );
}
