import type { MenuProps } from "antd";
import { Dropdown, Button } from "antd";
import { shallow } from "zustand/shallow";

import More from "../../assets/more.svg";
import Action from "../../assets/action.svg";
import useStore, { RFState } from "../../stores";
import { BLOCKS } from "contestants/blockIcons";

interface IKloudMDNodeHeaderProps {
    id: string;
    data?: any;
}

const selector = (state: RFState) => ({
    deleteNode: state.deleteNode,
    deleteBranchNode: state.deleteBranchNode,
    toggleNodeActionDropdown: state.toggleNodeActionDropdown,
});

const KloudMDNodeHeader = ({ id, data }: IKloudMDNodeHeaderProps) => {
    const { deleteNode, deleteBranchNode, toggleNodeActionDropdown } = useStore(selector, shallow);

    const items: MenuProps["items"] = [
        {
            label: (
                <Button
                    type="link"
                    onClick={(e) => {
                        e.stopPropagation();
                        if (data?.branchId) {
                            deleteBranchNode(id);
                        } else {
                            deleteNode(id);
                        }
                    }}
                >
                    Delete
                </Button>
            ),
            key: "delete",
        },
    ].filter((item) => !(data?.default && item.key === "delete"));

    const icon = BLOCKS.find((block) => block?.type === data.nodeType)?.icon;

    return (
        <div className="block-header">
            <div className="details">
                <div className="icon">{icon ? icon : <img src={Action} alt="action" />}</div>
                <div className="label">{data?.label}</div>
            </div>
            {items && items.length > 0 && (
                <div className="actions">
                    <Dropdown
                        menu={{ items }}
                        trigger={["click"]}
                        open={data?.visibleActions ?? false}
                        onOpenChange={(open) => {
                            toggleNodeActionDropdown(id, open);
                        }}
                    >
                        <Button type="text" onClick={(e) => e.stopPropagation()}>
                            <img src={More} alt="More" aria-hidden />
                        </Button>
                    </Dropdown>
                </div>
            )}
        </div>
    );
};

export default KloudMDNodeHeader;
