import axios, { AxiosInstance, AxiosError, InternalAxiosRequestConfig, AxiosResponse } from "axios";
import { camelizeKeys, decamelizeKeys } from "humps";

const api: AxiosInstance = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL || "https://dev.pilotpractice.com",
    headers: {
        "Content-Type": "application/json",
        "X-Requested-With": "XMLHttpRequest",
        "Access-Control-Allow-Origin": "*",
    },
    withCredentials: true,
});

api.interceptors.request.use((config: InternalAxiosRequestConfig) => {
    const newConfig = { ...config };

    if (newConfig.headers["Content-Type"] === "multipart/form-data") return newConfig;

    if (config.params) {
        newConfig.params = decamelizeKeys(config.params);
    }

    if (config.data) {
        newConfig.data = decamelizeKeys(config.data);
    }

    return newConfig;
});

api.interceptors.response.use(
    (response: AxiosResponse) => {
        if (response.data && response.headers["content-type"] === "application/json") {
            return camelizeKeys(response.data);
        }
        return response.data;
    },
    (error: AxiosError<unknown>) => {
        if (error.response?.status === 401) {
            window.location.href = `${
                process.env.REACT_APP_DASHBOARD_BASE_URL || "https://dashboard.pilotpractice.com"
            }/login`;
        }
        return Promise.reject(error.response);
    }
);

export default api;
