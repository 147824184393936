import { motion } from "framer-motion";
import styled from "styled-components";

export const SidebarStyled = styled(motion.div)`
    /* flex: 0 0 363px; */
    width: 311px;
    background-color: #fff;
    border: 1px solid #e8e8ef;
    box-sizing: border-box;
    padding-top: 85px;
    padding-left: 20px;
    height: 100vh;
    /* position: absolute; */
    z-index: 2;

    .closecard {
        position: absolute;
        margin-left: 288px;
        background-color: #fff;
        border-radius: 0 5px 5px 0;
        border-bottom: 1px solid #e8e8ef;
        border-right: 1px solid #e8e8ef;
        border-top: 1px solid #e8e8ef;
        width: 53px;
        height: 53px;
        text-align: center;
        z-index: 10;
        cursor: pointer;

        img {
            margin-top: 15px;
        }
    }

    .sidebar__container {
        height: 100vh;
        z-index: 3;

        .search {
            // TODO: Remove it later.
            display: none;

            img {
                position: absolute;
                margin-top: 10px;
                width: 18px;
                margin-left: 12px;
            }

            input {
                width: 266px;
                height: 40px;
                background-color: #fff;
                border: 1px solid #e8e8ef;
                box-sizing: border-box;
                box-shadow: 0 2px 8px rgb(34 34 87 / 5%);
                border-radius: 5px;
                text-indent: 35px;
                font-size: 16px;
            }
        }
    }
`;
