import styled from "styled-components";

interface Props {
    selected: boolean;
    isValid?: boolean;
    isPlaceholder?: boolean;
}

export const KloudMDNodeStyled = styled.div<Props>`
    /* Block element */
    padding-top: 10px;
    padding-bottom: 10px;
    width: 318px;
    border: ${({ selected, theme }) => (selected ? `2px solid ${theme.colors.primary}` : `1px solid transparent`)};
    ${({ isValid = true }) =>
        !isValid &&
        `
        border: 2px solid #ff4d4f;
    `}
    ${({ isPlaceholder }) =>
        isPlaceholder &&
        `
        border: 2px dashed #bbb;
    `}
    ${({ isValid = true, isPlaceholder }) =>
        !isValid &&
        isPlaceholder &&
        `
        border: 2px dashed #ff4d4f;
    `}
    transition-property: box-shadow, height;
    transition-duration: 0.2s;
    transition-timing-function: cubic-bezier(0.05, 0.03, 0.35, 1);
    border-radius: 5px;
    box-shadow: 0px 0px 30px rgba(22, 33, 74, 0);
    box-sizing: border-box;

    /* Block */
    background-color: #fff;
    ${({ isPlaceholder }) =>
        isPlaceholder &&
        `
        background-color: #fbfbfb;
    `}
    margin-top: 0px !important;
    box-shadow: 0px 4px 30px rgba(22, 33, 74, 0.05);

    ${({ selected }) =>
        selected &&
        `
        user-select: none;
    `}

    & .block-header {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .details {
            margin-left: 20px;
            display: flex;
            align-items: center;

            .icon {
                width: 28px;
                height: 28px;

                .anticon {
                    margin-top: 5px;
                }
            }

            .label {
                font-weight: 500;
                color: #253134;
                display: inline-block;
                vertical-align: middle;
                margin: 16px;
                margin-left: 8px;
                font-size: 16px;
            }
        }

        .actions {
            margin-right: 20px;
        }
    }

    .react-flow__handle {
        width: 12px;
        height: 12px;
        border-radius: 6px;
    }

    .react-flow__handle-top {
        top: -4px;
        opacity: 0;
    }

    .react-flow__handle-bottom {
        bottom: -4px;

        background-color: #217ce8;
        opacity: 0;
        transition: all 0.3s cubic-bezier(0.05, 0.03, 0.35, 1);

        &:after {
            content: "";
            display: block;
            width: 12px;
            height: 12px;
            background-color: #217ce8;
            transform: scale(1.7);
            opacity: 0.2;
            border-radius: 6px;
            margin-left: -1.5px;
            margin-top: -1.5px;
        }
    }
`;
