import React from "react";

import { Button, Card, Select, Input, Form } from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import { shallow } from "zustand/shallow";
import { isEmpty, uniqBy } from "lodash-es";

import { IConditionField, IConditionProps } from "types";
import useConditionEditorStore from "stores/useConditionEditorStore";
import { ConditionWrapperStyled, ConditionWrapperFormStyled } from "./Condition.styled";
import useStore, { RFState } from "../../../stores";
import { useGetConditionFieldValuesQuery } from "hooks/features/workflows/queries";

const selector = (state: RFState) => ({
    conditionFields: state.conditionFields,
});

export default function Condition({
    branchId,
    conditionSetIndex,
    conditionIndex,
    condition,
    canDelete = false,
    isDefault = false,
    field,
    remove,
}: IConditionProps) {
    const { conditionFields } = useStore(selector, shallow);
    const { deleteCondition, onConditionChange } = useConditionEditorStore();
    const [operators, setOperators] = React.useState<string[]>([]);
    const [currentConditionField, setCurrentConditionField] = React.useState<IConditionField>();
    const [currentOperator, setCurrentOperator] = React.useState<string>("");
    const [conditionFieldValueOptions, setConditionFieldValueOptions] = React.useState<
        { label: string; value: string }[]
    >([]);
    const { data: conditionFieldValueData, isLoading: isConditionFieldValueLoading } = useGetConditionFieldValuesQuery(
        currentConditionField?.id ?? 0,
        currentConditionField?.type === "select"
    );

    const getConditionOperators = (value: string) => {
        const conditionOperators =
            conditionFields.find((conditionField) => conditionField.field === value)?.operations ?? [];

        setOperators(conditionOperators);
    };

    const getCurrentConditionField = (value: string) => {
        const conditionField = conditionFields.find((cf) => cf.field === value);
        if (conditionField && !isEmpty(conditionField)) {
            setCurrentConditionField(conditionField);
        }
    };

    const handleConditionChange = (fieldName: string, value: any) => {
        onConditionChange(branchId, conditionSetIndex, conditionIndex, fieldName, value);

        if (fieldName === "field") {
            getCurrentConditionField(value as string);
            getConditionOperators(value as string);
        }

        if (fieldName === "condition") {
            setCurrentOperator(value as string);
        }
    };

    const handleDeleteCondition = () => {
        deleteCondition(branchId, conditionSetIndex, conditionIndex);
        remove(field.name);
    };

    const renderConditionFieldValueInput = () => {
        if (currentOperator === "exists" || currentOperator === "does_not_exist") {
            return null;
        }

        if (currentConditionField) {
            if (currentConditionField.type === "select") {
                return (
                    <Form.Item
                        name={[field.name, "value"]}
                        rules={[{ required: true, message: "Value is required field" }]}
                    >
                        <Select
                            className="value"
                            value={condition?.value}
                            onChange={(value) => handleConditionChange("value", value)}
                            options={conditionFieldValueOptions}
                            placeholder="Select"
                            disabled={isDefault}
                            getPopupContainer={() => document.getElementById("nodeSettingsPanelWrapper") as HTMLElement}
                            style={{ width: "100%" }}
                        />
                    </Form.Item>
                );
            }

            return (
                <Form.Item
                    name={[field.name, "value"]}
                    rules={[{ required: true, message: "Value is required field" }]}
                >
                    <Input
                        className="value"
                        value={condition?.value}
                        placeholder="Value"
                        onChange={(e) => handleConditionChange("value", e.target.value)}
                        disabled={isDefault}
                        style={{ width: "100%" }}
                    />
                </Form.Item>
            );
        }
    };

    React.useEffect(() => {
        if (condition?.field) {
            getCurrentConditionField(condition?.field);
            getConditionOperators(condition?.field);
            setCurrentOperator(condition?.condition ?? "");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [condition]);

    React.useEffect(() => {
        if (conditionFieldValueData && !isEmpty(conditionFieldValueData) && !isConditionFieldValueLoading) {
            setConditionFieldValueOptions(
                conditionFieldValueData.map((item) => ({
                    label: String(item),
                    value: String(item),
                }))
            );
        }
    }, [conditionFieldValueData, isConditionFieldValueLoading]);

    return (
        <ConditionWrapperStyled isDefault={isDefault}>
            <Card>
                <div>{conditionIndex >= 1 && <span>AND</span>}</div>
                <ConditionWrapperFormStyled>
                    <div className="conditon-form">
                        <div className="condition-input">
                            <Form.Item
                                name={[field.name, "field"]}
                                rules={[{ required: true, message: "Field is required field" }]}
                            >
                                <Select
                                    className="field"
                                    value={condition?.field}
                                    onChange={(value) => handleConditionChange("field", value)}
                                    options={uniqBy([...conditionFields], "id")?.map((conditionField) => ({
                                        label: conditionField.label,
                                        value: conditionField.field,
                                    }))}
                                    placeholder="Select"
                                    disabled={isDefault}
                                    getPopupContainer={() =>
                                        document.getElementById("nodeSettingsPanelWrapper") as HTMLElement
                                    }
                                    style={{ width: "100%" }}
                                />
                            </Form.Item>
                        </div>
                        <div className="condition-input">
                            <Form.Item
                                name={[field.name, "condition"]}
                                rules={[{ required: true, message: "Operator is required field" }]}
                            >
                                <Select
                                    className="condition"
                                    value={condition?.condition}
                                    onChange={(value) => handleConditionChange("condition", value)}
                                    options={operators.map((operator) => ({
                                        label: operator,
                                        value: operator,
                                    }))}
                                    placeholder="Select Operator"
                                    disabled={isDefault}
                                    getPopupContainer={() =>
                                        document.getElementById("nodeSettingsPanelWrapper") as HTMLElement
                                    }
                                    style={{ width: "100%" }}
                                />
                            </Form.Item>
                        </div>
                        <div className="condition-input">{renderConditionFieldValueInput()}</div>
                    </div>
                    {canDelete && (
                        <Button icon={<DeleteOutlined />} onClick={() => handleDeleteCondition()} type="text"></Button>
                    )}
                </ConditionWrapperFormStyled>
            </Card>
        </ConditionWrapperStyled>
    );
}
