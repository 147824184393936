import React from "react";

import { AnimatePresence, motion } from "framer-motion";
import { startCase } from "lodash-es";
import { Typography } from "antd";
import { shallow } from "zustand/shallow";
import { Node } from "reactflow";

import { INodeSettingsPanelProps } from "../../types";
import Close from "../../assets/close.svg";
import TriggerForm from "./TriggerForm";
import WaitForm from "./WaitForm";
import SendEmailForm from "./SendEmailForm";
import SendSMSForm from "./SendSMSForm";
import BranchForm from "./BranchForm";
import { NodeSettingsPanelWrapperStyled } from "./NodeSettingsPanel.styled";
import ContactCreatedTriggerForm from "./ContactCreatedTriggerForm";
import ContactUpdatedTriggerForm from "./ContactUpdatedTriggerForm";
import ContactStatusChangedTriggerForm from "./ContactStatusChangedTriggerForm";
import ContactDaysInStageTriggerForm from "./ContactDaysInStageTriggerForm";
import ContactRepliedTriggerForm from "./ContactRepliedTriggerForm";
import AppointmentStatusUpdatedTriggerForm from "./AppointmentStatusUpdatedTriggerForm";
import CreateTaskForm from "./CreateTaskForm";
import CreateNoteForm from "./CreateNoteForm";
import UpdateContactStage from "./UpdateContactStageForm";
import UpdateAppointmentStatus from "./UpdateAppointmentStatusForm";
import AssignToUserForm from "./AssignToUserForm/AssignToUserForm";
import RemoveAssignedUserForm from "./RemoveAssignedUserForm/RemoveAssignedUserForm";
import useStore, { RFState } from "stores";
import useKeyPress from "hooks/useKeyPress";
import IfForm from "./IfForm/IfForm";
import ContactUpdateForm from "./ContactUpdateForm";
import AssignLeadForm from "./AssignLeadForm";
import { useGetStaffs } from "hooks/features/staffs/queries";
import CreateTicketForm from "./CreateTicketForm";
import TicketAgeTriggerForm from "./TicketAgeTriggerForm";

const selector = (state: RFState) => ({
    onChangeNodeLabel: state.onChangeNodeLabel,
});

export default function NodeSettingsPanel({
    node,
    open = false,
    onClose,
    onDeleteNodes,
    onSubmit,
    wrapperRef,
}: INodeSettingsPanelProps) {
    const { onChangeNodeLabel } = useStore(selector, shallow);
    const title = startCase(String(node?.data?.label) ?? "");
    const [selectedNode, setSelectedNode] = React.useState<Node | undefined>();
    const { data: listStaffs } = useGetStaffs();

    const handleChangeNodeLabel = React.useCallback(
        (value: string) => {
            setSelectedNode((prevState) => {
                if (!prevState) return prevState;

                return {
                    ...prevState,
                    data: {
                        ...prevState.data,
                        label: value,
                    },
                };
            });

            if (node?.id) {
                onChangeNodeLabel(node?.id, value);
            }
        },
        [node, onChangeNodeLabel]
    );

    const renderForm = React.useCallback(() => {
        switch (selectedNode?.data?.nodeType) {
            case "contact.created":
                return (
                    <ContactCreatedTriggerForm
                        initialData={selectedNode?.data}
                        canDelete={!selectedNode?.data?.default}
                        onCancel={onClose}
                        onSubmit={onSubmit}
                        onDelete={onDeleteNodes}
                    />
                );
            case "contact.updated":
                return (
                    <ContactUpdatedTriggerForm
                        initialData={selectedNode?.data}
                        canDelete={!selectedNode?.data?.default}
                        onCancel={onClose}
                        onSubmit={onSubmit}
                        onDelete={onDeleteNodes}
                    />
                );
            case "contact.status_changed":
                return (
                    <ContactStatusChangedTriggerForm
                        initialData={selectedNode?.data}
                        canDelete={!selectedNode?.data?.default}
                        onCancel={onClose}
                        onSubmit={onSubmit}
                        onDelete={onDeleteNodes}
                    />
                );
            case "contact.days_in_stage":
                return (
                    <ContactDaysInStageTriggerForm
                        initialData={selectedNode?.data}
                        canDelete={!selectedNode?.data?.default}
                        onCancel={onClose}
                        onSubmit={onSubmit}
                        onDelete={onDeleteNodes}
                    />
                );
            case "contact.replied":
                return (
                    <ContactRepliedTriggerForm
                        initialData={selectedNode?.data}
                        canDelete={!selectedNode?.data?.default}
                        onCancel={onClose}
                        onSubmit={onSubmit}
                        onDelete={onDeleteNodes}
                    />
                );
            case "appointment.created":
                return (
                    <TriggerForm
                        initialData={selectedNode?.data}
                        canDelete={!selectedNode?.data?.default}
                        onCancel={onClose}
                        onSubmit={onSubmit}
                        onDelete={onDeleteNodes}
                    />
                );
            case "appointment.status_updated":
                return (
                    <AppointmentStatusUpdatedTriggerForm
                        initialData={selectedNode?.data}
                        canDelete={!selectedNode?.data?.default}
                        onCancel={onClose}
                        onSubmit={onSubmit}
                        onDelete={onDeleteNodes}
                    />
                );
            case "missed_call":
                return (
                    <TriggerForm
                        initialData={selectedNode?.data}
                        canDelete={!selectedNode?.data?.default}
                        onCancel={onClose}
                        onSubmit={onSubmit}
                        onDelete={onDeleteNodes}
                    />
                );
            case "lead":
                return (
                    <TriggerForm
                        initialData={selectedNode?.data}
                        canDelete={!selectedNode?.data?.default}
                        onCancel={onClose}
                        onSubmit={onSubmit}
                        onDelete={onDeleteNodes}
                    />
                );
            case "create_reminder":
                return (
                    <CreateTaskForm
                        initialData={selectedNode?.data}
                        canDelete={!selectedNode?.data?.default}
                        onCancel={onClose}
                        onSubmit={onSubmit}
                        onDelete={onDeleteNodes}
                    />
                );
            // TODO: Add Create Appointment Form.
            case "create_appointment":
                return (
                    <CreateNoteForm
                        initialData={selectedNode?.data}
                        canDelete={!selectedNode?.data?.default}
                        onCancel={onClose}
                        onSubmit={onSubmit}
                        onDelete={onDeleteNodes}
                    />
                );
            case "create_note":
                return (
                    <CreateNoteForm
                        initialData={selectedNode?.data}
                        canDelete={!selectedNode?.data?.default}
                        onCancel={onClose}
                        onSubmit={onSubmit}
                        onDelete={onDeleteNodes}
                    />
                );
            case "update_contact_stage":
                return (
                    <UpdateContactStage
                        initialData={selectedNode?.data}
                        canDelete={!selectedNode?.data?.default}
                        onCancel={onClose}
                        onSubmit={onSubmit}
                        onDelete={onDeleteNodes}
                    />
                );
            case "update_contact_field":
                return (
                    <UpdateContactStage
                        initialData={selectedNode?.data}
                        canDelete={!selectedNode?.data?.default}
                        onCancel={onClose}
                        onSubmit={onSubmit}
                        onDelete={onDeleteNodes}
                    />
                );
            case "wait":
                return (
                    <WaitForm
                        initialData={selectedNode?.data}
                        canDelete={!selectedNode?.data?.default}
                        onCancel={onClose}
                        onSubmit={onSubmit}
                        onDelete={onDeleteNodes}
                    />
                );
            case "email":
                return (
                    <SendEmailForm
                        initialData={selectedNode?.data}
                        canDelete={!selectedNode?.data?.default}
                        onCancel={onClose}
                        onSubmit={onSubmit}
                        onDelete={onDeleteNodes}
                    />
                );
            case "sms":
                return (
                    <SendSMSForm
                        initialData={selectedNode?.data}
                        canDelete={!selectedNode?.data?.default}
                        onCancel={onClose}
                        onSubmit={onSubmit}
                        onDelete={onDeleteNodes}
                    />
                );
            case "update_appointment_status":
                return (
                    <UpdateAppointmentStatus
                        initialData={selectedNode?.data}
                        canDelete={!selectedNode?.data?.default}
                        onCancel={onClose}
                        onSubmit={onSubmit}
                        onDelete={onDeleteNodes}
                    />
                );
            case "create_ticket":
                return (
                    <UpdateAppointmentStatus
                        initialData={selectedNode?.data}
                        canDelete={!selectedNode?.data?.default}
                        onCancel={onClose}
                        onSubmit={onSubmit}
                        onDelete={onDeleteNodes}
                    />
                );
            case "assign_to_user":
                return (
                    <AssignToUserForm
                        initialData={selectedNode?.data}
                        canDelete={!selectedNode?.data?.default}
                        onCancel={onClose}
                        onSubmit={onSubmit}
                        onDelete={onDeleteNodes}
                    />
                );
            case "remove_assigned_user":
                return (
                    <RemoveAssignedUserForm
                        initialData={selectedNode?.data}
                        canDelete={!selectedNode?.data?.default}
                        onCancel={onClose}
                        onSubmit={onSubmit}
                        onDelete={onDeleteNodes}
                    />
                );
            case "branch":
                return (
                    <BranchForm
                        initialData={selectedNode?.data}
                        canDelete={!selectedNode?.data?.default}
                        onCancel={onClose}
                        onSubmit={onSubmit}
                        onDelete={onDeleteNodes}
                    />
                );
            case "if":
                return (
                    <IfForm
                        initialData={selectedNode?.data}
                        canDelete={!selectedNode?.data?.default}
                        onCancel={onClose}
                        onSubmit={onSubmit}
                        onDelete={onDeleteNodes}
                    />
                );
            case "contact_update":
                return (
                    <ContactUpdateForm
                        initialData={selectedNode?.data}
                        canDelete={!selectedNode?.data?.default}
                        onCancel={onClose}
                        onSubmit={onSubmit}
                        onDelete={onDeleteNodes}
                    />
                );
            case "assign_lead":
                return (
                    <AssignLeadForm
                        initialData={selectedNode?.data}
                        canDelete={!selectedNode?.data?.default}
                        onCancel={onClose}
                        onSubmit={onSubmit}
                        onDelete={onDeleteNodes}
                    />
                );
            case "create_task":
                return (
                    <CreateTicketForm
                        initialData={selectedNode?.data}
                        canDelete={!selectedNode?.data?.default}
                        onCancel={onClose}
                        onSubmit={onSubmit}
                        onDelete={onDeleteNodes}
                    />
                );
            case "ticket.age":
                return (
                    <TicketAgeTriggerForm
                        initialData={selectedNode?.data}
                        canDelete={!selectedNode?.data?.default}
                        onCancel={onClose}
                        onSubmit={onSubmit}
                        onDelete={onDeleteNodes}
                    />
                );
            default:
                return <p>Empty</p>;
        }
    }, [onClose, onDeleteNodes, onSubmit, selectedNode?.data]);

    useKeyPress("Escape", () => {
        onClose();
    });

    React.useEffect(() => {
        if (node) {
            if (["assign_lead", "create_task", "create_reminder"].includes(String(node.data.nodeType))) {
                setSelectedNode({ ...node, data: { ...node.data, listStaffs: listStaffs?.data } });
            } else {
                setSelectedNode(node);
            }
        }
    }, [node, listStaffs]);

    return (
        <AnimatePresence>
            {node && (
                <NodeSettingsPanelWrapperStyled
                    initial={{ zIndex: -2 }}
                    animate={{ zIndex: open ? 10 : -2, transition: { delay: 0, duration: 0.3 } }}
                    ref={wrapperRef as React.Ref<HTMLDivElement> | undefined}
                    id="nodeSettingsPanelWrapper"
                >
                    {open && (
                        <motion.div
                            className={`properties ${open ? "expanded" : ""}`}
                            initial={{ right: -150, opacity: 0 }}
                            animate={{
                                right: open ? 0 : -150,
                                opacity: open ? 1 : 0,
                                transition: { delay: 0, duration: 0.3, ease: [0.05, 0.03, 0.35, 1] },
                            }}
                        >
                            <div className="close" onClick={() => onClose()} aria-hidden>
                                <img src={Close} alt="close" />
                            </div>
                            <div className="title">
                                {node?.data?.workflowType === "action" ? (
                                    <Typography.Paragraph editable={{ onChange: handleChangeNodeLabel }}>
                                        {selectedNode?.data?.label ?? ""}
                                    </Typography.Paragraph>
                                ) : (
                                    <Typography.Paragraph>{title}</Typography.Paragraph>
                                )}
                            </div>
                            {renderForm()}
                        </motion.div>
                    )}
                </NodeSettingsPanelWrapperStyled>
            )}
        </AnimatePresence>
    );
}
