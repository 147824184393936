/* eslint-disable @typescript-eslint/no-misused-promises */
import React from "react";

import { Form, Input, Select, InputNumber, Row, Col } from "antd";
import { omit } from "lodash-es";

import { IFormsProps } from "../../../types";
import FormFooter from "../FormFooter";

interface IFormInputs {
    title: string;
}

// TODO: ability to specify days.
export default function ContactDaysInStageTriggerForm({
    initialData,
    canDelete = true,
    onCancel,
    onSubmit,
    onDelete,
}: IFormsProps) {
    const [form] = Form.useForm();
    const initialValues = {
        title: initialData?.label ?? "",
    };

    const sumbit = (data: IFormInputs) => {
        onSubmit({
            ...omit(data, ["title"]),
            label: data.title,
        });
    };

    return (
        <div className="form-layout">
            <Form
                layout="vertical"
                form={form}
                initialValues={initialValues}
                onFinish={sumbit}
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 24 }}
            >
                <Form.Item
                    label="Trigger Name"
                    name="title"
                    rules={[{ required: true, message: "Trigger Name is required field" }]}
                >
                    <Input placeholder="Trigger Name" />
                </Form.Item>
                <Form.Item>
                    <Row gutter={8}>
                        <Col span={8}>
                            <Form.Item name="stage" rules={[{ required: true, message: "Stage is required field" }]}>
                                <Select
                                    className="stage"
                                    options={[
                                        { value: "stage1", label: "Stage1" },
                                        { value: "stage2", label: "Stage2" },
                                    ]}
                                    placeholder="Stage"
                                />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item
                                name="operator"
                                rules={[{ required: true, message: "Days In Stage is required field" }]}
                            >
                                <InputNumber className="field" placeholder="Days In Stage" style={{ width: "100%" }} />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form.Item>
                <FormFooter onCancel={onCancel} canDelete={canDelete} onDelete={onDelete} />
            </Form>
        </div>
    );
}
