import styled from "styled-components";

interface Props {
    isDefault: boolean;
}

export const ConditionWrapperStyled = styled.div<Props>`
    .ant-card-body {
        padding-top: 0;
        padding-bottom: ${({ isDefault }) => (isDefault ? "24px" : "0")};
    }
`;

export const ConditionWrapperFormStyled = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;

    .conditon-form {
        width: 95%;
        display: flex;
        justify-content: start;
        gap: 8px;

        .condition-input {
            flex: 1 1 20%;
            min-width: 20%;

            &:nth-child(2),
            &:nth-child(3) {
                flex: 2 1 40%;
            }
        }
    }
`;
