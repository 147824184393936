import React from "react";

import { Route, Routes as BaseRoutes, Outlet } from "react-router-dom";

import HomePage from "pages";
import useCSRFToken from "hooks/useCSRFToken";

interface IProectedRouteProps {
    children?: React.ReactNode;
}

const ProtectedRoute: React.FC<IProectedRouteProps> = ({ children }) => {
    const { loggedIn } = useCSRFToken();

    if (!loggedIn) return <></>;

    return (
        <>
            {children}
            <Outlet />
        </>
    );
};

export default function Routes() {
    return (
        <BaseRoutes>
            <Route element={<ProtectedRoute />}>
                <Route index element={<HomePage />} />
                <Route path={"workflows/:id"} element={<HomePage />} />
            </Route>
        </BaseRoutes>
    );
}
