// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { Editor } from "tinymce";
import { TConditionFields } from "types";

const register = (editor: Editor, conditionFields: TConditionFields = []) => {
    editor.ui.registry.addMenuButton("templatetags", {
        text: "Template Tags",
        fetch: (callback) => {
            const items = conditionFields.map((conditionField) => ({
                type: "menuitem",
                text: `${conditionField.label}`,
                onAction: () => {
                    editor.insertContent(`{{${conditionField.field}}}`);
                },
            }));
            callback(items);
        },
    });
};

export { register };
