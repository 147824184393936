import React from "react";

import { Button } from "antd";
import { DeleteOutlined } from "@ant-design/icons";

import { IFormFooterProps } from "types";
import { FormFooterWrapper } from "./FormFooter.styled";

export default function FormFooter({
    isLoading = false,
    canDelete = false,
    onCancel,
    onDelete,
    extraActions,
}: IFormFooterProps) {
    return (
        <FormFooterWrapper>
            <div className="left-actions">
                {canDelete && (
                    <Button type="default" danger size="large" onClick={onDelete} icon={<DeleteOutlined />}>
                        Delete
                    </Button>
                )}
                {extraActions && <>{extraActions}</>}
            </div>
            <div className="right-actions">
                <Button type="default" size="large" onClick={onCancel}>
                    Cancel
                </Button>
                <Button type="primary" size="large" htmlType="submit" loading={isLoading}>
                    Save
                </Button>
            </div>
        </FormFooterWrapper>
    );
}
