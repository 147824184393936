import React from "react";

import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";
import { SpinnerWrapper } from "./Spinner.styled";

interface IProps {
    fullscreen?: boolean;
}

const Spinner = ({ fullscreen = false }: IProps) => {
    return (
        <SpinnerWrapper className="spinner-wrapper" fullscreen={fullscreen}>
            <Spin className="spinner" indicator={<LoadingOutlined />} />
        </SpinnerWrapper>
    );
};

export default Spinner;
