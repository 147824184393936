import styled from "styled-components";

interface ITinyMCEEditorWrapper {
    error?: boolean;
}

export const TinyMCEEditorWrapper = styled.div<ITinyMCEEditorWrapper>`
    .tox-tinymce {
        border: 1px solid ${({ error }) => (error ? "#ff4d4f" : "#d9d9d9")};
        border-radius: 6px;
    }
`;
