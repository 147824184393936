import { IBoundingBox } from "types/utils";

export const getBoundingBox = (element: Element): IBoundingBox => {
    const elementBounds = element.getBoundingClientRect();

    return {
        centerX: elementBounds.left + elementBounds.width / 2,
        centerY: elementBounds.top + elementBounds.height / 2,
    };
};
