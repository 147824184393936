import { breakpoints } from "./breakpoints";

// import original module declarations
import { DefaultTheme } from "styled-components";
import { colors } from "./colors";

declare module "styled-components" {
    export interface DefaultTheme {
        colors: {
            primary: string;
            default: string; // a.k.a secondary
        };
        fonts: {
            body: string;
            heading: string;
        };
        breakpoints: {
            xs: string;
            sm: string;
            md: string;
            lg: string;
            xl: string;
            xxl: string;
        };
    }
}

export const theme: DefaultTheme = {
    colors: {
        ...colors,
    },
    fonts: {
        body: `'IBM Plex Sans', sans-serif`,
        heading: `'IBM Plex Sans', sans-serif`,
    },
    breakpoints: {
        ...breakpoints,
    },
};

export const antdTheme = {
    token: {
        colorPrimary: theme.colors.primary,
        colorDefault: theme.colors.default,
    },
};
