import React, { useEffect } from "react";

import { v4 as uuidv4 } from "uuid";
import { Card, Button, Input, Form, FormListOperation } from "antd";
import { PlusCircleOutlined, CloseCircleOutlined } from "@ant-design/icons";

import useConditionEditorStore from "stores/useConditionEditorStore";
import ConditionSets from "../ConditionSets";
import { IBranchProps } from "types";
import { BranchWrapperStyled } from "./Branch.styled";

export default function Branch({
    branch,
    canDelete = false,
    isSelected = false,
    isDefault = false,
    field,
    remove,
}: IBranchProps) {
    const ref = React.useRef<HTMLDivElement>(null);
    const { onBranchChange, deleteBranch, addConditionSet } = useConditionEditorStore();

    const handledeleteBranch = (id: string) => {
        if (id) {
            deleteBranch(String(id));
            remove(field.name);
        }
    };

    const handleAddConditionSet = (branchId: string, subOpt: FormListOperation) => {
        const conditionSet = [
            {
                id: uuidv4(),
            },
        ];
        addConditionSet(branchId, conditionSet);
        subOpt.add({
            conditions: [{}],
        });
    };

    useEffect(() => {
        if (ref && isSelected) {
            setTimeout(() => {
                if (ref.current) {
                    ref.current.scrollIntoView({
                        behavior: "smooth",
                        block: "end",
                    });
                }
            }, 500);
        }
    }, [ref, isSelected]);

    return (
        <div ref={ref}>
            <BranchWrapperStyled isSelected={isSelected}>
                <Card className="branch" key={branch.id}>
                    {canDelete && (
                        <div className="btn-actions">
                            <Button
                                className="btn-delete"
                                icon={<CloseCircleOutlined />}
                                onClick={() => handledeleteBranch(String(branch.id))}
                                danger
                                type="link"
                            ></Button>
                        </div>
                    )}
                    <div className="branch-name-input">
                        <Form.Item
                            label="Name"
                            name={[field.name, "name"]}
                            rules={[{ required: true, message: "Branch Name is required field" }]}
                        >
                            <Input
                                placeholder="Branch Name"
                                value={branch.name || ""}
                                onChange={(e) => onBranchChange(String(branch.id), e.target.value)}
                                disabled={isDefault}
                            />
                        </Form.Item>
                    </div>
                    <Form.Item>
                        <Form.List name={[field.name, "conditionSets"]}>
                            {(subFields, subOpt) => (
                                <div className="condition-sets">
                                    {branch?.conditionSets &&
                                        subFields.map((subField, conditionSetIndex) => (
                                            <ConditionSets
                                                key={subField.key}
                                                branchId={String(branch.id)}
                                                conditionSets={branch?.conditionSets ?? []}
                                                conditionSetIndex={conditionSetIndex}
                                                isDefault={isDefault}
                                                field={subField}
                                                remove={subOpt.remove}
                                            />
                                        ))}
                                    {!isDefault && (
                                        <div className="bottom-btn-actions">
                                            <Button
                                                className="btn-add"
                                                icon={<PlusCircleOutlined />}
                                                type="link"
                                                onClick={() => handleAddConditionSet(String(branch.id), subOpt)}
                                            >
                                                Add condition set
                                            </Button>
                                        </div>
                                    )}
                                </div>
                            )}
                        </Form.List>
                    </Form.Item>
                </Card>
            </BranchWrapperStyled>
        </div>
    );
}
