import React from "react";
import { NodeProps, Handle, Position } from "reactflow";
import styled from "styled-components";
import { PlusOutlined } from "@ant-design/icons";
import { KloudMDNodeStyled } from "components/KloudMDNodes/KloudMDNode.styled";

const PlaceholderNodeWrapper = styled.div`
    color: #bbb;
    margin: 14px;
    text-align: center;
`;

export default function PlaceholderNode({ data, isConnectable }: NodeProps) {
    return (
        <KloudMDNodeStyled selected={false} isValid={data?.isValid} isPlaceholder={true}>
            <Handle type="target" position={Position.Top} isConnectable={isConnectable} />
            <div className="block-container">
                <PlaceholderNodeWrapper>
                    <PlusOutlined style={{ fontSize: "20px" }} />
                </PlaceholderNodeWrapper>
            </div>
            <Handle type="source" position={Position.Bottom} isConnectable={isConnectable} />
        </KloudMDNodeStyled>
    );
}
