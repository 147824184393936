import React from "react";

import { Button, Radio } from "antd";

import { INavigatioProps } from "../../types";
import Arrow from "../../assets/arrow.svg";
import {
    NavigationLeftSideStyled,
    NavigationStyled,
    NavigatioButtonLeftStyled,
    NaviationCenterStyled,
} from "./NavigationBar.styled";
import EditableText from "components/EditableText";
import useStore, { RFState } from "../../stores";
import { shallow } from "zustand/shallow";

const selector = (state: RFState) => ({
    currentTab: state.currentTab,
    setCurrentTab: state.setCurrentTab,
});

export default function Navigation({ workflowName, onUpdateName, onSave, isLoading = false }: INavigatioProps) {
    const { currentTab, setCurrentTab } = useStore(selector, shallow);

    const onChangeTab = (tab: string) => {
        setCurrentTab(tab);
    };

    const onBack = () => {
        const url = `${process.env.REACT_APP_DASHBOARD_BASE_URL || "https://dashboard.pilotpractice.com"}/workflows`;
        window.location.assign(url);
    };

    return (
        <NavigationStyled id="navigation">
            <NavigationLeftSideStyled>
                <div className="details">
                    <div className="back" onClick={() => onBack()} aria-hidden>
                        <img src={Arrow} alt="Arrow" />
                    </div>
                    <div className="name">
                        <EditableText text={workflowName} onSave={onUpdateName} />
                    </div>
                </div>
            </NavigationLeftSideStyled>
            <NaviationCenterStyled id="centerswitch">
                <Radio.Group
                    value={currentTab}
                    onChange={(e) => onChangeTab(String(e.target.value))}
                    style={{ marginBottom: 16 }}
                    size="large"
                >
                    <Radio.Button value="builder">Builder</Radio.Button>
                    <Radio.Button value="settings">Settings</Radio.Button>
                </Radio.Group>
            </NaviationCenterStyled>
            <NavigatioButtonLeftStyled id="buttonsright" className="discard">
                <Button type="default" size="large">
                    Discard
                </Button>
                <Button type="primary" size="large" className="publish" onClick={() => onSave()} loading={isLoading}>
                    Save
                </Button>
            </NavigatioButtonLeftStyled>
        </NavigationStyled>
    );
}
