import React, { DependencyList } from "react";

export type KeyFilter = string | undefined;
export type Handler = (event: KeyboardEvent) => void;

// React hooks dectects when the user is pressing a specific key.
const useKeyPress = (key: KeyFilter, callback: Handler, deps: DependencyList = [key]) => {
    const useHandler = React.useMemo(() => {
        const handler: Handler = (handlerEvent) => {
            if (handlerEvent.key === key) return callback(handlerEvent);
        };

        return handler;
    }, [deps]);

    React.useEffect(() => {
        document.addEventListener("keydown", useHandler);

        return () => {
            document.removeEventListener("keydown", useHandler);
        };
    }, [deps]);
};

export default useKeyPress;
