import { useQuery } from "@tanstack/react-query";

import { WorkflowAPI } from "apis/workflow";
import { TConditionFieldValueResponse } from "types";

export function useGetConditionFieldValuesQuery(conditionField: number, enabled: boolean) {
    return useQuery<TConditionFieldValueResponse, Error>(
        ["getConditionFieldValues", conditionField],
        () => WorkflowAPI.getConditionFieldValues(conditionField),
        {
            refetchOnWindowFocus: false,
            enabled: !!conditionField && enabled,
        }
    );
}
