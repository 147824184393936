import React from "react";
import { NodeProps, Handle, Position } from "reactflow";
import { KloudMDNodeStyled } from "../KloudMDNode.styled";

import KloudMDNodeHeader from "../../KloudMDNodeHeader";

const KloudMDOutputNode = ({ id, data, selected, isConnectable }: NodeProps) => {
    return (
        <KloudMDNodeStyled selected={selected} isValid={data?.isValid}>
            <Handle type="target" position={Position.Top} isConnectable={isConnectable} />
            <div className="block-container">
                <KloudMDNodeHeader id={id} data={data} />
            </div>
        </KloudMDNodeStyled>
    );
};

KloudMDOutputNode.displayName = "KloudMDOutputNode";

export default React.memo(KloudMDOutputNode);
