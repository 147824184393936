/* eslint-disable @typescript-eslint/no-misused-promises */
import React from "react";

import { Form, Input } from "antd";
import { omit } from "lodash-es";

import { IFormsProps } from "../../../types";
import FormFooter from "../FormFooter";

interface IFormInputs {
    title: string;
}

export default function ContactCreatedTriggerForm({
    initialData,
    canDelete = true,
    onCancel,
    onSubmit,
    onDelete,
}: IFormsProps) {
    const [form] = Form.useForm();
    const initialValues = {
        title: initialData?.label ?? "",
    };

    const sumbit = (data: IFormInputs) => {
        onSubmit({
            ...omit(data, ["title"]),
            label: data.title,
        });
    };

    return (
        <div className="form-layout">
            <Form layout="vertical" form={form} initialValues={initialValues} onFinish={sumbit}>
                <Form.Item
                    label="Trigger Name"
                    name="title"
                    rules={[{ required: true, message: "Trigger Name is required field" }]}
                >
                    <Input placeholder="Trigger Name" />
                </Form.Item>
                <FormFooter onCancel={onCancel} canDelete={canDelete} onDelete={onDelete} />
            </Form>
        </div>
    );
}
