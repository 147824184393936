import styled from "styled-components";

export const PreviewEmailTemplateWrapper = styled.div`
    .test-email-container {
        position: absolute;
        width: 25%;
        height: 100%;
        overflow-y: auto;
        padding-right: 30px;
        border: none;
        box-shadow: 10px 0 10px -10px #d9d9d9;
        margin-top: -20px;
        margin-bottom: -20px;
        padding-top: 20px;
        padding-bottom: 20px;

        .variable-header {
            margin-top: 20px;
        }

        header {
            border-bottom: 1px dashed #0e405f;
            margin-bottom: 20px;
        }

        .variable-item {
            border-bottom: 1px dashed #0e405f;
            padding: 0 5px;
            margin-bottom: 10px;

            .variable-data {
                padding-bottom: 10px;
            }
        }
    }

    .email-preview-container {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        flex: 1;
        margin-left: 30%;

        .email-preview-subject {
            margin-top: 10px;
        }

        .email-preview-message {
            margin-top: 10px;

            iframe {
                width: 100%;
                height: 400px;
                background-color: #f5f5f5;
                border: none;
                overflow-y: auto;
            }
        }

        .send-email-actions {
            margin-top: 10px;
            display: flex;
            justify-content: right;
            gap: 8px;
        }

        .email-attachment-files {
            font-size: 14px;

            .attachment-file-name {
                padding: 0 8px;
            }
        }
    }
`;
