import React from "react";

import { Form, Select, InputNumber, Row, Col } from "antd";

import { IFormsProps } from "../../../types";
import FormFooter from "../FormFooter";
import { TICKET_TYPES } from "../CreateTicketForm/CreateTicketForm";
import { isEmpty } from "lodash-es";

interface IFormInputs {
    title: string;
    older_than_minutes: number;
    types: string[];
}

export default function TicketAgeTriggerForm({
    initialData,
    canDelete = true,
    onCancel,
    onSubmit,
    onDelete,
}: IFormsProps) {
    const [form] = Form.useForm();
    const initialValues = {
        title: initialData?.label ?? "",
        older_than_minutes: initialData?.config?.older_than_minutes ?? null,
        types: initialData?.config?.types ?? [],
    };

    React.useEffect(() => {
        if (initialData && !isEmpty(initialData)) {
            form.setFieldValue(
                "older_than_minutes",
                initialData?.config?.older_than_minutes ?? initialData?.config?.olderThanMinutes
            );
            form.setFieldValue("types", initialData?.config?.types);
        }
    }, [initialData, form]);

    const sumbit = (data: IFormInputs) => {
        onSubmit({
            config: data,
        });
    };

    return (
        <div className="form-layout">
            <Form
                layout="vertical"
                form={form}
                initialValues={initialValues}
                onFinish={sumbit}
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 24 }}
            >
                <Form.Item>
                    <Row gutter={8}>
                        <Col span={12}>
                            <Form.Item
                                name="older_than_minutes"
                                label="Older than minutes"
                                rules={[{ required: true, message: "Older than minutes is required field" }]}
                            >
                                <InputNumber type="number" placeholder="Older than minutes" style={{ width: "100%" }} />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name="types"
                                label="Types"
                                rules={[{ required: true, message: "Types is required field" }]}
                            >
                                <Select
                                    mode="multiple"
                                    options={TICKET_TYPES?.map((type) => ({
                                        label: type.label,
                                        value: type.value,
                                    }))}
                                    placeholder="Types"
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form.Item>
                <FormFooter onCancel={onCancel} canDelete={canDelete} onDelete={onDelete} />
            </Form>
        </div>
    );
}
