import React from "react";

import Search from "../../assets/search.svg";

interface IBlockSearchProp {
    onSearch: (value: React.ChangeEvent<HTMLInputElement>) => void;
}

export default function SearchInput({ onSearch }: IBlockSearchProp) {
    return (
        <div className="search">
            <img src={Search} alt="Search" />
            <input type="text" placeholder="Search blocks" onChange={(e) => onSearch(e)} />
        </div>
    );
}
