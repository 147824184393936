interface IDoubleBrace {
    type: string;
    value: string;
    fullValue: string;
}

type TDoubleBraceMatches = IDoubleBrace[];

export function getDoubleBraces(text: string): TDoubleBraceMatches {
    const pattern = /{{(.*?)}}|.+?(?={{|$)/g;
    const matches = Array.from(text.matchAll(pattern))
        .filter((e) => !!e[1])
        .map((e) => {
            return {
                type: e[1] ? "dynamic" : "static",
                value: e[1] ?? e[0],
                fullValue: e[0],
            };
        })
        .filter((e) => e.type === "dynamic");

    return matches;
}

export function evaluateVariable(variable: string): string {
    const variables = {
        first_name: "Test First Name",
        last_name: "Test Last Name",
        stage: "Test Stage",
    };

    return variables?.[variable as keyof typeof variables] ?? variable;
}

export function parseDoubleBraces(text: string) {
    let parsedText = text;

    getDoubleBraces(text).forEach((match) => {
        parsedText = parsedText.replace(match.fullValue, evaluateVariable(match.value));
    });

    return parsedText;
}
