import { IStaff, TCommonPagination } from "types";
import api from "./axios";

const getStaffs = async () => {
    const response = await api.get<TCommonPagination<IStaff>, TCommonPagination<IStaff>>("/api/staff", {
        params: {
            limit: 100,
        },
    });

    return response;
};

export const StaffAPI = {
    getStaffs,
};
