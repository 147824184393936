export const breakpoints = {
    xs: "400px", // for small screen mobile
    sm: "576px", // for mobile screen
    md: "768px", // for tablets
    lg: "992px", // for laptops
    xl: "1200px", // for desktop / monitors
    xxl: "1400px", // for big screens
};

export const device = {
    xs: `(max-width: ${breakpoints.xs})`,
    sm: `(max-width: ${breakpoints.sm})`,
    md: `(max-width: ${breakpoints.md})`,
    lg: `(max-width: ${breakpoints.lg})`,
    xl: `(max-width: ${breakpoints.xl})`,
    xxl: `(max-width: ${breakpoints.xxl})`,
};
