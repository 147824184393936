/* eslint-disable @typescript-eslint/no-misused-promises */
import React from "react";

import { Col, Form, Input, Row, Select } from "antd";

import { IFormsProps } from "../../../types";
import FormFooter from "../FormFooter";
import TextArea from "antd/es/input/TextArea";
import { isEmpty } from "lodash-es";

interface IFormInputs {
    title: string;
    name: string;
    description: string;
    staffId: string;
    dueIn: string;
}

interface IListStaffs {
    label: string;
    value: number;
}

const UNIT = [
    {
        id: 1,
        value: "minutes",
    },
    {
        id: 2,
        value: "days",
    },
    {
        id: 3,
        value: "weeks",
    },
];

export default function CreateTaskForm({ initialData, canDelete = true, onCancel, onSubmit, onDelete }: IFormsProps) {
    const [form] = Form.useForm();
    const [listInitStaffs, setListInitStaffs] = React.useState<IListStaffs[] | any>([]);

    const initialValues = {
        title: initialData.label,
        nameTask: String(initialData?.nameTask ?? ""),
        description: String(initialData?.description ?? ""),
        staffId: String(initialData?.staffId ?? ""),
        reminderTime: String(initialData?.reminderTime ?? ""),
        unit: String(initialData?.unit ?? ""),
    };

    React.useEffect(() => {
        if (initialData.listStaffs && initialData.listStaffs.length > 0) {
            setListInitStaffs(
                initialData.listStaffs.map((staff: any) => ({
                    value: staff.id,
                    label: `${staff.firstName ?? ""} ${staff.lastName ?? ""}`,
                }))
            );
        }
    }, [initialData]);

    React.useEffect(() => {
        if (initialData && !isEmpty(initialData)) {
            form.setFieldValue("nameTask", initialData?.nameTask);
            form.setFieldValue("description", initialData?.description);
            form.setFieldValue("staffId", initialData?.staffId);
            form.setFieldValue("reminderTime", initialData?.reminderTime);
            form.setFieldValue("unit", initialData?.unit);
        }
    }, [initialData, form]);

    const sumbit = (data: IFormInputs) => {
        onSubmit(data);
    };

    return (
        <div className="form-layout">
            <Form layout="vertical" form={form} initialValues={initialValues} onFinish={sumbit}>
                <Row gutter={16}>
                    <Col span={24}>
                        <Form.Item
                            label="Task Name"
                            name="nameTask"
                            rules={[{ required: true, message: "Task Name is required field" }]}
                        >
                            <Input placeholder="Task Name" />
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item name="description" label="Description">
                            <TextArea placeholder="Description" />
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item
                            name="staffId"
                            label="Assign To"
                            rules={[{ required: true, message: "Assign To is required field" }]}
                        >
                            <Select
                                placeholder="Select"
                                allowClear
                                options={listInitStaffs}
                                optionFilterProp="children"
                            />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            name="reminderTime"
                            label="Reminder time"
                            rules={[{ required: true, message: "Reminder time is required field" }]}
                        >
                            <Input type="number" placeholder="Reminder time" />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item name="unit" label="Unit">
                            <Select
                                placeholder="Select"
                                options={UNIT}
                                defaultActiveFirstOption
                                optionFilterProp="children"
                            />
                        </Form.Item>
                    </Col>
                </Row>

                <FormFooter onCancel={onCancel} canDelete={canDelete} onDelete={onDelete} />
            </Form>
        </div>
    );
}
