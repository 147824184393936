import React from "react";

import { Editor } from "@tinymce/tinymce-react";
import { Editor as ITinyMCEEditor } from "tinymce";
import { Form } from "antd";

import EmailTemplateTagPlugin from "components/TinyMCEEditor/plugins/emailtemplatetags";
import { TConditionFields } from "types";
import { TinyMCEEditorWrapper } from "./TinyMCEEditor.styled";

interface IEditorWrapperProps {
    value?: string;
    plugins?: string[] | string;
    menubar?: string;
    toolbar?: string;
    onChange?(valueText: string): void;
    onChangeText?(valueText: string): void;
    conditionFields?: TConditionFields;
    uiMode?: "combined" | "split" | undefined;
}

const TinyMCEEditor = ({
    value,
    plugins,
    menubar,
    toolbar,
    onChange,
    onChangeText,
    uiMode = "combined",
    ...props
}: IEditorWrapperProps) => {
    const { errors } = Form.Item.useStatus();

    const handleUpdate = (valueText: string, editor: ITinyMCEEditor) => {
        if (onChange) onChange(valueText);
        if (onChangeText) onChangeText(editor.getContent({ format: "text" }));
    };

    return (
        <TinyMCEEditorWrapper error={errors.length > 0}>
            <Editor
                {...props}
                apiKey={process.env.REACT_APP_TINY_API_KEY || ""}
                init={{
                    setup: (editor: ITinyMCEEditor) => {
                        EmailTemplateTagPlugin(editor, props?.conditionFields ?? []);
                    },
                    branding: false,
                    plugins: plugins,
                    menubar,
                    toolbar,
                    ui_mode: uiMode,
                    valid_children: "+body[style]",
                }}
                value={value}
                onEditorChange={handleUpdate}
            />
        </TinyMCEEditorWrapper>
    );
};

export default TinyMCEEditor;
