import React from "react";

import { useQuery } from "@tanstack/react-query";
import { shallow } from "zustand/shallow";
import { startCase, camelCase, omit } from "lodash-es";

import Workflow from "components/Workflow";
import Spinner from "components/Spinner";
import { WorkflowAPI } from "apis/workflow";
import useStore, { RFState } from "../stores";
import { IBlock } from "../types";
import { BLOCKS } from "contestants/blockIcons";

const selector = (state: RFState) => ({
    setBlocks: state.setBlocks,
});

export default function HomePage() {
    const { setBlocks } = useStore(selector, shallow);
    const { data: triggers, isLoading } = useQuery(["getTriggers"], () => WorkflowAPI.getTriggers(), {
        refetchOnWindowFocus: false,
        refetchOnReconnect: false,
        refetchOnMount: false,
        retry: false,
    });

    React.useEffect(() => {
        if (!isLoading && triggers) {
            const defaultBlocks: IBlock[] = BLOCKS.filter((block) => block.workflowType !== "trigger").map(
                (block, index) => {
                    return {
                        ...block,
                        id: 100 + Number(index),
                        name: block?.name ?? startCase(camelCase(String(block.type).replace("_", " "))),
                    };
                }
            );
            const blocks: IBlock[] = triggers
                .map((trigger) => {
                    const icon = BLOCKS.find((block) => block.type === trigger.type)?.icon;

                    return {
                        ...omit(trigger, ["triggerable", "createdAt", "updatedAt"]),
                        workflowType: "trigger",
                        icon,
                    } as IBlock;
                })
                .concat(defaultBlocks);
            setBlocks(blocks);
        }
    }, [isLoading, triggers, setBlocks]);

    if (isLoading) return <Spinner fullscreen={true} />;

    return <Workflow />;
}
