import React from "react";

import { ConfigProvider } from "antd";
import { ReactFlowProvider } from "reactflow";
import { ThemeProvider } from "styled-components";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";

import Routes from "Routes";
import { antdTheme, theme } from "./styles/theme";
import { GlobalStyles } from "styles/global";

import "antd/dist/reset.css";
import "reactflow/dist/style.css";
import "./styles.css";

const queryClient = new QueryClient();

function App() {
    return (
        <ConfigProvider theme={{ ...antdTheme }}>
            <ThemeProvider theme={theme}>
                <QueryClientProvider client={queryClient}>
                    <GlobalStyles />
                    <ReactFlowProvider>
                        <Routes />
                    </ReactFlowProvider>
                    <ReactQueryDevtools initialIsOpen={false} />
                </QueryClientProvider>
            </ThemeProvider>
        </ConfigProvider>
    );
}

export default App;
